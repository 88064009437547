import React, { ChangeEvent } from 'react';
import { ButtonGroup, IconButton, Stack, styled, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { changeMyNetworkPage, changePageSize } from '@state/users/myNetworkSlice';
import { PageSizeSelect } from '@common';

import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { ListPaginationProps } from './types';
import { usePagination } from '../../../utils/pagination';

const ElementsText = styled(Typography)(({ theme }) => ({
  opacity: 0.5,
  fontSize: theme.typography.pxToRem(14),
  marginRight: 20
}));

const PaginationButton = styled(IconButton)(({ theme }) => ({
  width: 24,
  height: 24,
  color: theme.palette.text.primary,
  backgroundColor: '#f5f5f5',
  border: '1px solid #b6c5de',
  '&:first-of-type': {
    marginRight: 20
  },
  '&.Mui-disabled': {
    borderColor: '#f5f5f5'
  },
  '.MuiSvgIcon-root': {
    fontSize: theme.typography.pxToRem(20)
  }
}));

export const ListViewPagination = ({
  paginationSelector,
  onPageChange,
  isPageSizeSelectable
}: ListPaginationProps) => {
  const dispatch = useAppDispatch();
  const { totalCount, currentPage, pageSize } = useAppSelector(paginationSelector);
  const { totalPageCount, firstElement, lastElement } = usePagination({
    totalCount,
    currentPage,
    pageSize
  });

  const handlePrevPage = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(changePageSize(+event.target.value));
    dispatch(changeMyNetworkPage(0));
  };

  return (
    <Stack direction="row" alignItems="center">
      {isPageSizeSelectable && (
        <PageSizeSelect
          pageSize={pageSize}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChangeRowsPerPage(event)}
        />
      )}
      <ElementsText>
        {firstElement + 1} - {lastElement + 1} of {totalCount}
      </ElementsText>
      <ButtonGroup>
        <PaginationButton onClick={handlePrevPage} disabled={currentPage === 0} disableTouchRipple>
          <ChevronLeft />
        </PaginationButton>
        <PaginationButton
          onClick={handleNextPage}
          disabled={currentPage === totalPageCount - 1}
          disableTouchRipple
        >
          <ChevronRight />
        </PaginationButton>
      </ButtonGroup>
    </Stack>
  );
};
