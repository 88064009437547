// @ts-nocheck
import React, { useState, useMemo } from 'react';
import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { User } from '@state/types';
import { ListEntity } from '@shared/components/list-view/types';
import { Statuses, UserRole } from '@helpers';
import { getFirstWordUpperCase } from '@utils/getFirstWordToUpperCase';
import Progress from '@shared/components/Progress';
import { useAppDispatch } from '@hooks/useStore';
import { UpdateUserStatus } from '@state/users/types';
import { updateUserStatusThunk } from '@state/users/userSlice';
import { fetchUsers } from '@state/users/thunks';
import { useTranslation } from 'react-i18next';

export const CustomerListStatusSelect = (data: ListEntity) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const {
    state: customerStatus,
    user_id: userId,
    password_expired: passwordExpired
  } = data as User;
  const [status, setStatus] = useState<Statuses>(customerStatus ?? '');

  const handleChange = async (event: {
    target: { value: React.SetStateAction<Statuses | string> };
  }) => {
    setLoading(true);

    setStatus(event.target.value as Statuses);

    if (userId) {
      const userData: UpdateUserStatus = {
        user_id: userId,
        status: event.target.value as Statuses
      };

      await dispatch(updateUserStatusThunk(userData));
      await dispatch(fetchUsers(UserRole.CUSTOMER));
    }

    setLoading(false);
  };

  const options = useMemo(
    () => [
      {
        id: 1,
        title: getFirstWordUpperCase(Statuses.Active),
        value: Statuses.Active
      },
      {
        id: 2,
        title: getFirstWordUpperCase(Statuses.Backup),
        value: Statuses.Backup
      },
      {
        id: 3,
        title: getFirstWordUpperCase(Statuses.Deleted),
        value: Statuses.Deleted
      },
      {
        id: 4,
        title: getFirstWordUpperCase(Statuses.Suspend),
        value: Statuses.Suspend
      }
    ],
    []
  );

  const isStatusInvited = status === Statuses.Invited;

  return (
    <Stack width="100%" height="auto" position="relative">
      {isStatusInvited ? (
        <Typography style={{ opacity: passwordExpired ? 0.5 : 1 }}>
          {t('customer.statuses.invited')}
        </Typography>
      ) : (
        <Select value={status} sx={{ height: '40px' }} onChange={handleChange}>
          {options.map(({ id, title, value }) => (
            <MenuItem key={id} value={value}>
              {title}
            </MenuItem>
          ))}
        </Select>
      )}
      {isLoading && <Progress size={25} position="absolute" />}
    </Stack>
  );
};
