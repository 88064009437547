import { FileSize } from '@helpers';

export const getSizeFormat = (size: number): string => {
  const GbValue = 1024 * 1024 * 1024;
  const MbValue = 1024 * 1024;
  const KbValue = 1024;

  if (size > GbValue) {
    return `${(size / GbValue).toFixed(1)}${FileSize.GB}`;
  }
  if (size > MbValue) {
    return `${(size / MbValue).toFixed(1)}${FileSize.MB}`;
  }
  if (size > KbValue) {
    return `${(size / KbValue).toFixed(1)}${FileSize.KB}`;
  }

  return `${size}${FileSize.B}`;
};

export const getShortSizeFormat = (size: number): string => {
  const GbValue = 1024 * 1024 * 1024;
  const MbValue = 1024 * 1024;
  const KbValue = 1024;

  if (size > GbValue) {
    return `${(size / GbValue).toFixed(0)}${FileSize.GB}`;
  }
  if (size > MbValue) {
    return `${(size / MbValue).toFixed(0)}${FileSize.MB}`;
  }
  if (size > KbValue) {
    return `${(size / KbValue).toFixed(0)}${FileSize.KB}`;
  }

  return `${size}${FileSize.B}`;
};
