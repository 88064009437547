import * as Sentry from '@sentry/react';
import { httpClientIntegration, captureConsoleIntegration } from '@sentry/integrations';

enum Environments {
  Production = 'production',
  Development = 'development',
  Staging = 'staging'
}

export const ENVIRONMENT = process.env.REACT_APP_ENV as Environments;

const SentryConfigByEnv = {
  dsn:
    'https://af3dc78639d9505ff1fc240773c8b6af@o4506694425837568.ingest.sentry.io/4506694427213824',
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    httpClientIntegration({
      failedRequestStatusCodes: [[400, 599]]
    }),
    captureConsoleIntegration({ levels: ['error'] })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

// eslint-disable-next-line @typescript-eslint/dot-notation
export default SentryConfigByEnv;
