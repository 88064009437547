import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { SxProps } from '@mui/material/styles';

import { AuthContext } from '@contexts';
import { useDispatch } from 'react-redux';
import { cleanupState } from '@state/files/filesSlice';
import { LocalStorageService } from '@services';
import { LocalStorageKeys, PAGES } from '@helpers';
import { UserAvatar } from '@common';
import useGetCustomerInfo from '@hooks/useGetCustomerInfo';

interface AccountMenuProps {
  sx?: SxProps;
}

export const AccountMenu = ({ sx }: AccountMenuProps) => {
  const { signOut, user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const fullName = `${user?.attributes?.given_name || ''} ${user?.attributes?.family_name || ''}`;
  const email = user?.attributes?.email || '';
  const { refetch } = useGetCustomerInfo();
  const isPreviewPage = window.location.pathname.includes('/preview');
  const styleType: 'light' | 'dark' = isPreviewPage ? 'light' : 'dark';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccountInfo = () => {
    history.push('/home/account-information');
    setAnchorEl(null);
    refetch();
  };

  const handleLogout = async () => {
    await signOut();
    LocalStorageService.delete(LocalStorageKeys.ATTEMPTED_UPDATE_PLAN);

    history.push(PAGES.Login);
    dispatch(cleanupState());
  };

  return (
    <Stack direction="row" alignItems="center" sx={sx}>
      <UserAvatar />
      <Button
        id="account-settings"
        aria-controls="account-settings-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        disableRipple
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        sx={{ height: 40, ml: 1, color: styleType === 'light' ? 'white' : 'text.primary' }}
      >
        <Stack>
          <Typography noWrap sx={{ fontSize: '0.875rem', fontWeight: '500' }}>
            {fullName}
          </Typography>
          <Typography sx={{ fontSize: '0.625rem', fontWeight: '400', opacity: 0.6 }}>
            {email}
          </Typography>
        </Stack>
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'account-settings'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAccountInfo} disableRipple>
          Account Information
        </MenuItem>
        <MenuItem onClick={handleLogout} disableRipple>
          Log Out
        </MenuItem>
      </Menu>
    </Stack>
  );
};
