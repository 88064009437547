import React from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setIsUpload } from '@state/files/filesSlice';
import { selectUploadProgress } from '@state/users/userSlice';
import { useAppSelector } from '@hooks/useStore';

interface StyleProps {
  styleType: 'light' | 'dark';
}

const UploadImage = styled.div<StyleProps>`
  display: flex;
  width: 24px;
  height: 24px;
  mask-size: contain;
  mask: url(/images/icons/upload_icon.svg) no-repeat center;
  background: ${({ styleType }) => (styleType === 'light' ? '#83E2DA' : '#252a34')};
`;

const Percent = styled.div<StyleProps>`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  gap: 2px;
  color: ${({ styleType }) => (styleType === 'light' ? '#83E2DA' : '#252a34')};
`;

const Container = styled.div<StyleProps>`
  display: flex;
  padding: 7px;
  border-radius: 30px;
  border: 2px solid ${({ styleType }) => (styleType === 'light' ? '#83E2DA' : '#252a34')};
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    border: 2px solid ${({ styleType }) => (styleType === 'light' ? '#e2f5f3' : '#343b48')};
    background: ${({ styleType }) =>
      styleType === 'light' ? 'rgba(131, 226, 218, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
  }
  &:hover ${UploadImage} {
    background: ${({ styleType }) => (styleType === 'light' ? '#e2f5f3' : '#343b48')};
  }
  &:hover ${Percent} {
    color: ${({ styleType }) => (styleType === 'light' ? '#e2f5f3' : '#343b48')};
  }
`;

export const UploadProgress = () => {
  const dispatch = useDispatch();
  const { percent, status } = useAppSelector(selectUploadProgress);

  const openUploadDialog = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(setIsUpload({ id: performance.now(), mode: undefined }));
  };
  const isPreviewPage = window.location.pathname.includes('/preview');
  const styleType: 'light' | 'dark' = isPreviewPage ? 'light' : 'dark';

  return isPreviewPage || status ? (
    <Container styleType={styleType} onClick={openUploadDialog}>
      <UploadImage styleType={styleType} />
      {status && (
        <Percent styleType={styleType}>
          {status}
          {!!percent && <span style={{ width: '45px', textAlign: 'center' }}>{percent}%</span>}
        </Percent>
      )}
    </Container>
  ) : null;
};
