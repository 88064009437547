import { DateTime } from 'luxon';
import { TFunction } from 'react-i18next';
import { i18n as i18next } from 'i18next';
import { CustomerListExpirationDateSelect } from '@common';
import { ListColumnDef } from '@shared/components/list-view';
import { UserInfoColumn } from '@features/users-view/UserInfoColumn';
import { EditUserIcon, CustomerListPlanSelect, CustomerListStatusSelect } from '@features/admin';
import { dataFormatterListDataTypeToPercent } from '@utils/dataFormatterListDataTypeToPercent';

export const customerColumns = (t: TFunction<'translation'>, i18n: i18next) => {
  const columns: ListColumnDef[] = [
    {
      key: 'given_name',
      headerName: t('usersView.list.columns.name'),
      component: UserInfoColumn,
      width: '15%',
      sortable: true
    },
    { key: 'email', headerName: t('usersView.list.columns.email'), sortable: true, width: '20%' },
    {
      key: 'expiration',
      headerName: t('usersView.list.columns.dateExpires'),
      width: '12%',
      component: CustomerListExpirationDateSelect,
      sortable: true
    },
    {
      key: 'subscription_size',
      headerName: t('usersView.list.columns.plan'),
      width: '10%',
      component: CustomerListPlanSelect,
      sortable: true
    },
    {
      key: 'storage_used',
      headerName: t('usersView.list.columns.used'),
      sortable: true,
      dataFormatter: dataFormatterListDataTypeToPercent,
      width: '5%'
    },
    {
      key: 'logins_count',
      headerName: t('usersView.list.columns.loginsCount'),
      sortable: true,
      width: '10%'
    },
    {
      key: 'login_time',
      headerName: t('usersView.list.columns.loginTime'),
      sortable: true,
      width: '10%'
    },
    {
      key: 'last_active',
      headerName: t('usersView.list.columns.lastLogin'),
      sortable: true,
      width: '10%',
      dataFormatter: (value) => {
        if (!value) return '';

        return DateTime.fromSQL(value?.toString() || '').toFormat('DD', {
          locale: i18n.language
        });
      }
    },
    {
      key: 'created',
      headerName: t('usersView.list.columns.customersCreated'),
      sortable: true,
      width: '10%',
      dataFormatter: (value) => {
        if (!value) return '';

        return DateTime.fromSQL(value?.toString() || '').toFormat('DD', {
          locale: i18n.language
        });
      }
    },
    {
      key: 'state',
      headerName: t('usersView.list.columns.status'),
      sortable: true,
      component: CustomerListStatusSelect,
      width: '10%'
    },
    {
      key: '',
      headerName: t('usersView.list.columns.action'),
      width: '5%',
      component: EditUserIcon
    }
  ];
  return columns;
};
