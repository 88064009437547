// @ts-nocheck
import React, { memo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IconButton, Stack, styled } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

import useOnClickOutside from '@hooks/useOnClickOutside';
import { useAppSelector } from '@hooks/useStore';
import { ListProps } from './types';
import { ListViewPagination } from './ListViewPagination';
import { ListViewHeadRow, ListViewRow } from './ListRow';

const List = styled(Stack)({
  overflow: 'hidden',
  backgroundColor: '#ffffff',
  border: '1px solid #e2ebfa',
  borderRadius: '12px'
});

const ListScrollContainer = styled(Stack)({
  overflowY: 'auto',
  overflowX: 'hidden'
});

const ListActions = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2)
}));

export const ListView = memo(
  ({
    columns,
    actions,
    onRowSelect,
    onSortChange,
    onPageChange,
    onRowClick,
    entitySelectors,
    dndEnabled = false,
    dndSettings,
    isPageSizeSelectable
  }: ListProps) => {
    const rowIds = useAppSelector(entitySelectors.ids);
    const [editMode, setEditMode] = useState(false);
    const rowsContainerRef = useRef();

    useOnClickOutside(rowsContainerRef, () => {
      setEditMode(false);
    });

    const rows: JSX.Element[] = rowIds.map((id, index) => {
      return (
        <ListViewRow
          id={id}
          index={index}
          columns={columns}
          key={id.toString()}
          onSelect={onRowSelect}
          onClick={onRowClick}
          onEditingStart={() => setEditMode(true)}
          onEditingEnd={() => setEditMode(false)}
          entitySelectors={entitySelectors}
          dndEnabled={dndEnabled && !editMode}
          dndSettings={dndSettings}
          editMode={editMode}
        />
      );
    });

    return (
      <List>
        <ListActions
          direction="row"
          alignItems="center"
          justifyContent={actions ? 'space-between' : 'end'}
        >
          {actions}
          <Stack direction="row">
            <ListViewPagination
              paginationSelector={entitySelectors.pagination}
              onPageChange={onPageChange}
              isPageSizeSelectable={isPageSizeSelectable}
            />
            <IconButton sx={{ ml: 2 }}>
              <CachedIcon />
            </IconButton>
          </Stack>
        </ListActions>
        <ListScrollContainer>
          <ListViewHeadRow
            columns={columns}
            onRowSelect={onRowSelect}
            onSortChange={onSortChange}
            entitySelectors={entitySelectors}
          />
          <DndProvider backend={HTML5Backend}>
            <Stack ref={rowsContainerRef}>{rows}</Stack>
          </DndProvider>
        </ListScrollContainer>
      </List>
    );
  },
  (prevProps, nextProps) => prevProps.columns === nextProps.columns
);
