import { useMemo } from 'react';
import { File } from '@features/files/types';

import { Pagination } from '@state/types';
import { PREVIEW_PAGE_SIZE } from '@helpers';
import { Page } from './types';

export const getBorderElements = ({ totalCount, currentPage, pageSize }: Pagination) => {
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const firstElement = currentPage * pageSize;

  let lastElement = (currentPage + 1) * pageSize - 1;
  if (lastElement >= totalCount) {
    lastElement = totalCount - 1;
  }

  return {
    totalPageCount,
    firstElement,
    lastElement
  };
};

export const usePagination = ({ totalCount, currentPage, pageSize }: Pagination) => {
  return useMemo(() => {
    return getBorderElements({ totalCount, currentPage, pageSize });
  }, [totalCount, currentPage, pageSize]);
};
