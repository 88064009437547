import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, SxProps } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  FileDownloadOutlined,
  PersonAddAltOutlined,
  RemoveCircleOutlineOutlined
} from '@mui/icons-material';

const BlueButtonWithIcon = styled(Button)({
  textTransform: 'none'
});

const RedButtonWithIcon = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '13px',
  backgroundColor: theme.palette.warning.light,
  color: '#1a1818',
  '&:hover, &:active, &:focus': {
    backgroundColor: theme.palette.warning.main,
    cursor: 'pointer'
  }
}));

/* eslint-disable react/no-unused-prop-types */
export interface IButtons {
  disabled?: boolean;
  variant?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps;
  buttonText?: string;
}

export const BrowseFilesButton = (props: IButtons) => {
  const { t } = useTranslation();
  const { disabled, onClick, sx } = props;

  return (
    <Button disabled={disabled} variant="contained" onClick={onClick} sx={sx}>
      {t('buttons.browseFiles')}
    </Button>
  );
};

export const UploadFilesButton = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<PropsWithChildren<IButtons>>
>(({ children, disabled, onClick, sx }, ref) => {
  const { t } = useTranslation();
  return (
    <BlueButtonWithIcon
      ref={ref}
      disabled={disabled}
      variant="contained"
      startIcon={children}
      onClick={onClick}
      sx={sx}
    >
      {t('buttons.uploadFiles')}
    </BlueButtonWithIcon>
  );
});

export const DeleteFilesButton = (props: IButtons) => {
  const { t } = useTranslation();
  const { disabled, onClick, sx } = props;

  return (
    <BlueButtonWithIcon
      disabled={disabled}
      variant="contained"
      startIcon={<DeleteOutlineIcon />}
      onClick={onClick}
      sx={sx}
    >
      {t('buttons.deleteFiles')}
    </BlueButtonWithIcon>
  );
};

export const TrashButton = (props: IButtons) => {
  const { t } = useTranslation();
  const { disabled, onClick } = props;

  return (
    <RedButtonWithIcon
      disabled={disabled}
      variant="contained"
      startIcon={<DeleteOutlineIcon />}
      onClick={onClick}
    >
      {t('buttons.trash')}
    </RedButtonWithIcon>
  );
};

export const RemoveUserButton = (props: IButtons) => {
  const { t } = useTranslation();
  const { disabled, onClick, sx } = props;

  return (
    <BlueButtonWithIcon
      disabled={disabled}
      variant="contained"
      startIcon={<RemoveCircleOutlineOutlined />}
      onClick={onClick}
      sx={sx}
    >
      {t('buttons.removeUser')}
    </BlueButtonWithIcon>
  );
};

export const ExportButton = (props: IButtons) => {
  const { t } = useTranslation();
  const { disabled, onClick, sx } = props;

  return (
    <BlueButtonWithIcon
      disabled={disabled}
      variant="contained"
      startIcon={<FileDownloadOutlined />}
      onClick={onClick}
      sx={sx}
    >
      {t('buttons.export')}
    </BlueButtonWithIcon>
  );
};

export const PersonAddButton = (props: PropsWithChildren<IButtons>) => {
  const { children, disabled, onClick, sx } = props;

  return (
    <BlueButtonWithIcon
      disabled={disabled}
      variant="contained"
      startIcon={<PersonAddAltOutlined />}
      onClick={onClick}
      sx={sx}
    >
      {children}
    </BlueButtonWithIcon>
  );
};
