const wait = (ms = 1000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const poll = async <T>(
  action: () => Promise<any>,
  fnCondition: (response: any) => boolean,
  ms: number
) => {
  let result: T = await action();
  while (!fnCondition(result)) {
    // eslint-disable-next-line no-await-in-loop
    await wait(ms);
    // eslint-disable-next-line no-await-in-loop
    result = await action();
  }
  return result;
};
