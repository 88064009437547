import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

interface IProps {
  open: boolean;
}

export const PageLoader = ({ open }: IProps) => {
  return (
    <Backdrop sx={{ color: '#ffffff', zIndex: 9999 }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
