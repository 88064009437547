import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';
import update from 'immutability-helper';

import { ViewPageSize } from '@helpers';
import { Pagination, SelectUserActionPayload } from '@state/types';
import { RootState } from '@store';
import { getBorderElements } from '@utils/pagination';
import { RequestStatus, Sort } from '@utils/types';
import { fetchMyNetwork } from './thunks';
import { networkUserToSearchString, updateTotalMyNetworkCount, usersCompareFn } from './utils';
import { NetworkUser } from './types';

export interface MyNetworkState extends EntityState<NetworkUser> {
  selectedIds: EntityId[];
  sort: Sort;
  searchTerm: string;
  pagination: Pagination;
  status: RequestStatus;
  error: string | null | undefined;
  users: NetworkUser[];
}

const myNetworkAdapter = createEntityAdapter<NetworkUser>({
  selectId: (user) => user.user_id
});

const initialState: MyNetworkState = myNetworkAdapter.getInitialState({
  selectedIds: [],
  sort: {
    key: '',
    direction: false
  },
  searchTerm: '',
  pagination: {
    totalCount: 0,
    currentPage: 0,
    pageSize: ViewPageSize.LIST
  },
  status: RequestStatus.IDLE,
  error: null,
  users: []
});

const myNetworkSlice = createSlice({
  name: 'myNetwork',
  initialState,
  reducers: {
    select: (
      state: MyNetworkState,
      { payload: { ids, selected } }: PayloadAction<SelectUserActionPayload>
    ) => {
      return selected
        ? update(state, { selectedIds: { $push: ids } })
        : {
            ...state,
            selectedIds: state.selectedIds.filter((selectedId) => !ids.includes(selectedId))
          };
    },
    changeSort: (state: MyNetworkState, { payload }: PayloadAction<Sort>) => {
      state.sort = payload;
    },
    changePage: (state: MyNetworkState, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload;
    },
    resetSelectedUsersIds: (state: MyNetworkState) => {
      state.selectedIds = [];
    },
    changePageSize: (state: MyNetworkState, { payload }: PayloadAction<number>) => {
      state.pagination.pageSize = payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchMyNetwork.pending, (state: MyNetworkState) => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(
        fetchMyNetwork.fulfilled,
        (state: MyNetworkState, action: PayloadAction<NetworkUser[]>) => {
          state.status = RequestStatus.IDLE;
          state.users = action.payload;
          myNetworkAdapter.setAll(state, action.payload);
          updateTotalMyNetworkCount(state);
        }
      )
      .addCase(fetchMyNetwork.rejected, (state: MyNetworkState, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message;
      })
});

export default myNetworkSlice.reducer;

export const {
  selectAll: selectAllNetworkUsers,
  selectById: selectNetworkUserById
} = myNetworkAdapter.getSelectors((state: RootState) => state.myNetwork);

export const selectedNetworkUserIds = (state: RootState) => state.myNetwork.selectedIds;
export const selectNetworkUsersSort = (state: RootState) => state.myNetwork.sort;
export const selectNetworkUsersPagination = (state: RootState) => state.myNetwork.pagination;

export const selectNetworkUsers = (state: RootState) => state.myNetwork.users;

export const isNetworkUserSelected = (state: RootState, id: EntityId) => {
  return state.myNetwork.selectedIds.includes(id);
};

export const selectSearchedNetworkUsers = createSelector(
  selectAllNetworkUsers,
  (state: RootState) => state.myNetwork.searchTerm,
  (entities, searchTerm) => {
    return entities.filter((user) =>
      searchTerm.trim().length ? networkUserToSearchString(user).includes(searchTerm) : true
    );
  }
);

export const selectSortedNetworkUserIds = createSelector(
  selectSearchedNetworkUsers,
  (state: RootState) => state.myNetwork.sort,
  (entities, sort) => {
    return (sort.direction === false
      ? [...entities]
      : [...entities].sort(usersCompareFn(sort))
    ).map((user) => user.user_id);
  }
);

export const selectNetworkUsersPageIds = createSelector(
  selectSortedNetworkUserIds,
  (state: RootState) => state.myNetwork.pagination,
  (ids, pagination) => {
    const { firstElement, lastElement } = getBorderElements(pagination);
    return ids.slice(firstElement, lastElement + 1);
  }
);

export const {
  resetSelectedUsersIds,
  changePageSize,
  select: changeSelectedNetworkUsers,
  changeSort: changeMyNetworkSort,
  changePage: changeMyNetworkPage
} = myNetworkSlice.actions;
