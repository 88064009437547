import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getJwtTokenFromCurrentSession } from '@utils/getJwtTokeFromCurrentSession';
import { ENVIRONMENT, PLATFORM } from '@aws';
import { processFile } from '@features/files/utils';

const httpLink = createHttpLink({
  uri: PLATFORM[ENVIRONMENT].graphql_endpoint
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getJwtTokenFromCurrentSession();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listFiles: {
          keyArgs: ['directory_id', 'access_type', 'query', 'content_type'], // Кэшируем запросы по этим аргументам,
          merge(existing, incoming) {
            console.log('existing', existing);
            console.log('incoming', incoming);
            if (!existing) {
              return { ...incoming, files: incoming.files.map((file: File) => processFile(file)) };
            }
            return {
              ...existing,
              files: [...existing.files, ...incoming.files.map((file: File) => processFile(file))] // Объединяем старые и новые данные
            };
          }
        }
      }
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network' // Получаем из кэша, но при этом обновляем данные
    }
    // query: {
    //   fetchPolicy: 'cache-first' // Используем кэш в первую очередь
    // }
  },
  cache
});

export default client;
