import {
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  PayloadAction
} from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { $AuthApi } from '@services';
import { setGlobalErrorToastMessage, setGlobalSuccessToastMessage } from '@common';
import { AxiosError } from 'axios';
import type { RootState } from '@store';
import { getBorderElements } from '@utils/pagination';
import { RequestStatus, Sort, UploadProgress } from '@utils/types';
import { fetchUserByUserId, fetchUsers } from '@state/users/thunks';
import { Nullable } from 'src/globalTypes';
import { DEFAULT_AVAILABILITY, Subscriptions, UserRole } from '@helpers';
import { getFormByFormData } from '@utils/getFormByFormData';
import { filterUserBySearchTerm, updateTotalUsersCount, usersCompareFn } from './utils';
import { UsersState, User, SelectUserActionPayload, UserProfileData, ErrorBody } from '../types';
import { CreateUserModel, UpdateUserModel, UpdateUserStatus } from './types';

export const createUserThunk = createAsyncThunk<AxiosError<ErrorBody>, CreateUserModel>(
  'create/user',
  async (userData, { rejectWithValue }) => {
    try {
      const form = getFormByFormData(userData);
      const response = await $AuthApi.post('/users', form);
      return response.data;
    } catch (err) {
      const error = err as AxiosError<ErrorBody['data']>;
      return rejectWithValue(error?.response?.data?.error ?? '');
    }
  }
);
const updateUser: AsyncThunkPayloadCreator<
  any,
  UpdateUserModel | UpdateUserStatus | { subscription: Subscriptions },
  {}
> = async (userData, { rejectWithValue }) => {
  try {
    const form = getFormByFormData(userData);
    const response = await $AuthApi.put('/users', form);

    return response.data;
  } catch (err) {
    const error = err as AxiosError<ErrorBody['data']>;
    return rejectWithValue(error?.response?.data?.error ?? '');
  }
};

export const sendForgotResetPasswordByStaff = createAsyncThunk<AxiosError<ErrorBody>, string>(
  'user/staff-forgot-password-data',
  async (email, { rejectWithValue }) => {
    try {
      const response = await $AuthApi.get(`/users/${email}/staff-reset-password`);
      setGlobalSuccessToastMessage(
        'Successfully sent new temporary password!',
        'Please check your email'
      );

      return response.data;
    } catch (err) {
      setGlobalErrorToastMessage(
        'Cannot send a new temporary password.',
        'Please try again later.'
      );

      const error = err as AxiosError<ErrorBody['data']>;
      return rejectWithValue(error?.response?.data?.error ?? '');
    }
  }
);

export const updateUserThunk = createAsyncThunk<any, UpdateUserModel>('update/user', updateUser);

export const updateUserStatusThunk = createAsyncThunk<any, UpdateUserStatus>(
  'update-status/user',
  updateUser
);

export const updateUserSubscriptionThunk = createAsyncThunk<
  any,
  UpdateUserModel | { subscription: Subscriptions }
>('update-subscription/user', updateUser);

const usersAdapter = createEntityAdapter<User>({
  selectId: (user) => user.user_id
});

const initialState: UsersState = usersAdapter.getInitialState({
  role: UserRole.CUSTOMER,
  loading: false,
  selectedIds: [],
  activeUserId: '',
  filter: UserRole.STAFF,
  sort: {
    key: '',
    direction: false
  },
  searchTerm: '',
  pagination: {
    totalCount: 0,
    currentPage: 0,
    pageSize: 20
  },
  status: RequestStatus.IDLE,
  error: null,
  user: null,
  uploadProgress: {
    percent: 0,
    status: ''
  }
});

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeFilter: (state: UsersState, { payload }: PayloadAction<UserRole>) => {
      state.filter = payload;
      updateTotalUsersCount(state);
    },
    select: (
      state: UsersState,
      { payload: { ids, selected } }: PayloadAction<SelectUserActionPayload>
    ) => {
      return selected
        ? update(state, { selectedIds: { $push: ids } })
        : {
            ...state,
            selectedIds: state.selectedIds.filter((selectedId) => !ids.includes(selectedId))
          };
    },
    clearSelectedIds: (state: UsersState) => {
      state.selectedIds = [];
    },
    changeSort: (state: UsersState, { payload }: PayloadAction<Sort>) => {
      state.sort = payload;
    },
    add: (state: UsersState, action: PayloadAction<User>) => {
      usersAdapter.addOne(state, action.payload);
      updateTotalUsersCount(state);
    },
    changePage: (state: UsersState, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload;
    },
    setActiveId: (state: UsersState, { payload }: PayloadAction<EntityId>) => {
      state.activeUserId = payload;
    },
    changeSearchTerm: (state: UsersState, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
      updateTotalUsersCount(state);
    },
    resetSearchTerm: (state: UsersState) => {
      state.searchTerm = '';
    },
    changeUser: usersAdapter.updateMany,
    changeRequestStatus: (state: UsersState, { payload }: PayloadAction<RequestStatus>) => {
      state.status = payload;
    },
    setUploadProgress: (state: UsersState, { payload }: PayloadAction<UploadProgress | {}>) => {
      state.uploadProgress = { ...state.uploadProgress, ...payload };
    },
    // setUploadProgressPercent: (state: UsersState, { payload }: PayloadAction<number>) => {
    //   state.uploadProgress.percent = payload;
    // },
    // setUploadProgressStatus: (state: UsersState, { payload }: PayloadAction<string>) => {
    //   state.uploadProgress.status = payload;
    // },
    resetUserProfileData: (state: UsersState) => {
      state.user = null;
    },
    resetRequestStatus: (state: UsersState) => {
      state.status = RequestStatus.IDLE;
    },
    changeRole: (state: UsersState, { payload }: PayloadAction<UserRole>) => {
      state.role = payload;
    },
    resetError: (state: UsersState) => {
      state.error = '';
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchUsers.pending, (state: UsersState) => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(fetchUsers.fulfilled, (state: UsersState, action: PayloadAction<User[]>) => {
        state.status = RequestStatus.SUCCEEDED;
        usersAdapter.setAll(state, action.payload);
        updateTotalUsersCount(state);
      })
      .addCase(fetchUsers.rejected, (state: UsersState, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message;
      })

      .addCase(fetchUserByUserId.pending, (state: UsersState) => {
        state.error = '';
        state.status = RequestStatus.LOADING;
      })
      .addCase(
        fetchUserByUserId.fulfilled,
        (state: UsersState, action: PayloadAction<Nullable<UserProfileData>>) => {
          state.status = RequestStatus.SUCCEEDED;
          state.user = action.payload;
        }
      )
      .addCase(fetchUserByUserId.rejected, (state: UsersState, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message;
      })

      .addCase(createUserThunk.pending, (state: UsersState) => {
        state.status = RequestStatus.LOADING;
        state.error = '';
      })
      .addCase(createUserThunk.rejected, (state: UsersState, action: PayloadAction<any>) => {
        state.status = RequestStatus.FAILED;
        state.error = action.payload;
      })

      .addCase(updateUserThunk.pending, (state: UsersState) => {
        state.status = RequestStatus.LOADING;
        state.error = '';
      })
      .addCase(updateUserThunk.rejected, (state: UsersState, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message;
      })

      .addCase(updateUserStatusThunk.pending, (state: UsersState) => {
        state.status = RequestStatus.LOADING;
        state.error = '';
      })
      .addCase(updateUserStatusThunk.rejected, (state: UsersState, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message;
      })

      .addCase(sendForgotResetPasswordByStaff.fulfilled, (state: UsersState) => {
        state.status = RequestStatus.SUCCEEDED;
      })
      .addCase(sendForgotResetPasswordByStaff.rejected, (state: UsersState, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message;
      })
});

// selectors
export const { selectById: selectUserById, selectAll: selectAllUsers } = usersAdapter.getSelectors(
  (state: RootState) => state.users
);

export const selectedUserIds = (state: RootState) => state.users.selectedIds;
export const selectUsersSort = (state: RootState) => state.users.sort;
export const selectUsersPagination = (state: RootState) => state.users.pagination;
export const selectUserRole = (state: RootState) => state.users.filter;
export const selectUserSearchTerm = (state: RootState) => state.users.searchTerm;
export const selectUserRequestStatus = (state: RootState) => state.users.status;
export const isUserSelected = (state: RootState, id: EntityId) => {
  return state.users.selectedIds.includes(id);
};
export const selectUserByUserId = (state: RootState) => state.users.user;

export const selectUploadProgress = (state: RootState) => state.users.uploadProgress;

// TODO REFACTOR
export const selectFilteredUsers = createSelector(
  selectAllUsers,
  selectUserRole,
  (users, filter) => {
    const filteredUsersList = users.filter((user) =>
      filter === UserRole.CUSTOMER
        ? user.account_type === filter
        : user.account_type !== UserRole.CUSTOMER
    );

    if (filter === UserRole.STAFF) {
      return filteredUsersList.map((user) => {
        if (user.availability) return user;
        return {
          ...user,
          availability: DEFAULT_AVAILABILITY
        } as User;
      });
    }

    return filteredUsersList;
  }
);

export const selectSearchedUsers = createSelector(
  selectFilteredUsers,
  selectUserSearchTerm,
  (entities, searchTerm) => {
    return entities.filter(filterUserBySearchTerm(searchTerm));
  }
);

export const selectSortedUserIds = createSelector(
  selectSearchedUsers,
  (state: RootState) => state.users.sort,
  (entities, sort) => {
    return (sort.direction === false
      ? [...entities]
      : [...entities].sort(usersCompareFn(sort))
    ).map((user) => user.user_id);
  }
);
export const selectUsersPageIds = createSelector(
  selectSortedUserIds,
  (state: RootState) => state.users.pagination,
  (ids, pagination) => {
    const { firstElement, lastElement } = getBorderElements(pagination);
    return ids.slice(firstElement, lastElement + 1);
  }
);
export const selectUsersPage = createSelector(
  selectUsersPageIds,
  selectSearchedUsers,
  (ids, entities) => entities.filter((entity) => ids.includes(entity.user_id))
);

export const selectActiveUserData = (state: RootState) => state.users;
// reducer
export default userSlice.reducer;

// actions
export const {
  changeUser,
  changeRequestStatus,
  resetUserProfileData,
  resetRequestStatus,
  select: selectUsers,
  clearSelectedIds: clearSelectedUsersId,
  changeFilter: changeUserListFilter,
  changeSort: changeUsersSort,
  changePage: changeUsersPage,
  setActiveId: setActiveUserId,
  changeSearchTerm: changeUsersSearchTerm,
  changeRole,
  resetSearchTerm,
  resetError,
  setUploadProgress
} = userSlice.actions;
