import { DEFAULT_AVAILABILITY, SortDirection, Statuses, UserRole } from '@helpers';
import { MyNetworkState } from '@state/users/myNetworkSlice';
import { NetworkUser } from '@state/users/types';
import { Sort } from '@utils/types';
import { User, UsersState } from '../types';

export const usersCompareFn = ({ key, direction }: Sort) => (
  a: User | NetworkUser,
  b: User | NetworkUser
) => {
  const aValue = a[key] ?? '';
  const bValue = b[key] ?? '';

  const localeCompare = aValue?.toString().localeCompare(bValue?.toString());

  const statusPriority = {
    [Statuses.Pending]: 6,
    [Statuses.Invited]: 5,
    [Statuses.Active]: 4,
    [Statuses.Suspend]: 3,
    [Statuses.Backup]: 2,
    [Statuses.Deleted]: 1
  };

  switch (key) {
    case 'last_active':
    case 'created':
    case 'expiration':
    case 'subscription':
      if (!aValue && !bValue) {
        return 0;
      }

      if (aValue && !bValue) {
        return -1;
      }

      if (!aValue && bValue) {
        return 1;
      }

      return direction === SortDirection.DESC ? localeCompare * -1 : localeCompare;
    case 'storage_used':
      if (direction === SortDirection.DESC) {
        return Number(bValue) - Number(aValue);
      }

      if (direction === SortDirection.ASC) {
        return Number(aValue) - Number(bValue);
      }

      return 0;
    case 'subscription_size':
      if (direction === SortDirection.DESC) {
        return Number(aValue) - Number(bValue);
      }

      if (direction === SortDirection.ASC) {
        return Number(bValue) - Number(aValue);
      }

      return 0;
    case 'state':
      if (!aValue && !bValue) {
        return 0;
      }

      if (aValue && !bValue) {
        return -1;
      }

      if (!aValue && bValue) {
        return 1;
      }

      return direction === SortDirection.DESC
        ? (statusPriority[bValue as Statuses] ?? 0) - (statusPriority[aValue as Statuses] ?? 0)
        : (statusPriority[aValue as Statuses] ?? 0) - (statusPriority[bValue as Statuses] ?? 0);

    case 'availability': {
      const isAValueActive = aValue === DEFAULT_AVAILABILITY;
      const isBValueActive = bValue === DEFAULT_AVAILABILITY;
      if (direction === SortDirection.DESC) {
        return Number(isBValueActive) - Number(isAValueActive);
      }
      if (direction === SortDirection.ASC) {
        return Number(isAValueActive) - Number(isBValueActive);
      }
      return 0;
    }

    default:
      return direction === SortDirection.DESC ? localeCompare : localeCompare * -1;
  }
};

export const userToSearchString = ({ given_name, family_name, email }: User) => {
  return `${given_name}${family_name}${email}`;
};

export const updateTotalUsersCount = (state: UsersState) => {
  const { searchTerm, filter } = state;
  state.pagination.currentPage = 0;
  state.pagination.totalCount = Object.values(state.entities)
    .filter((user) =>
      filter === UserRole.CUSTOMER
        ? user?.account_type === filter
        : user?.account_type !== UserRole.CUSTOMER
    )
    .filter((user) =>
      searchTerm.trim().length ? userToSearchString(user as User).includes(searchTerm) : true
    ).length;
};

export const networkUserToSearchString = ({ name, email }: NetworkUser) => {
  return `${name}${email}`;
};

export const updateTotalMyNetworkCount = (state: MyNetworkState) => {
  const { searchTerm } = state;
  state.pagination.totalCount = Object.values(state.entities).filter((user) =>
    searchTerm.trim().length
      ? networkUserToSearchString(user as NetworkUser).includes(searchTerm)
      : true
  ).length;
};

export const filterUserBySearchTerm = (searchTerm: string) => (user: User) =>
  searchTerm.trim().length ? userToSearchString(user).includes(searchTerm) : true;
