import { ListItemAvatar, styled } from '@mui/material';

export const UserAvatarWrapper = styled(ListItemAvatar)(({ theme }) => ({
  margin: 0,
  minWidth: 30,
  '& .MuiAvatar-root': {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1)
  }
}));
