import { createContext } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { AnyType } from './globalTypes';

export type AuthUser =
  | (CognitoUser & {
      challengeName: string;
      error: string;
      attributes: {
        [key: string]: string;
      };
    })
  | null;

interface AuthContextState {
  user: AuthUser | null;
  signIn: (email: string, password: string) => Promise<AuthUser>;
  signOut: () => Promise<void>;
  isLoading?: boolean;
  idUserToken: { [key: string]: any } | undefined;
  refreshSession: () => Promise<{
    idToken: AnyType;
    refreshToken: AnyType;
    accessToken: AnyType;
  }>;
}

export const AuthContext = createContext<AuthContextState>({
  user: null,
  signIn: () => Promise.resolve(null),
  signOut: () => Promise.resolve(),
  isLoading: false,
  idUserToken: undefined,
  refreshSession: () =>
    Promise.resolve({
      idToken: null,
      refreshToken: null,
      accessToken: null
    })
});
