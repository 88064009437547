import { DateTime } from 'luxon';
import { TFunction } from 'react-i18next';
import { i18n as i18next } from 'i18next';

import { ListColumnDef } from '@shared/components/list-view';
import { UserInfoColumn } from '@features/users-view/UserInfoColumn';
import { StaffStatusSwitcher } from '@features/users-view/StaffStatusSwitcher';
import { EditUserIcon } from '@features/admin';

export const staffColumns = (t: TFunction<'translation'>, i18n: i18next) => {
  const columns: ListColumnDef[] = [
    {
      key: 'given_name',
      headerName: t('usersView.list.columns.name'),
      sortable: true,
      component: UserInfoColumn,
      width: '12%'
    },
    { key: 'email', headerName: t('usersView.list.columns.email'), sortable: true, width: '20%' },
    {
      key: 'account_type',
      headerName: t('usersView.list.columns.role'),
      width: '10%',
      sortable: true
    },
    {
      key: 'created',
      headerName: t('usersView.list.columns.dateCreated'),
      width: '10%',
      dataFormatter: (value) => {
        if (value) {
          return DateTime.fromSQL(value?.toString() || '').toFormat('DD', {
            locale: i18n.language
          });
        }
        return '';
      },
      sortable: true
    },
    {
      key: 'last_active',
      headerName: t('usersView.list.columns.lastLogin'),
      sortable: true,
      width: '10%',
      dataFormatter: (value) => {
        if (value) {
          return DateTime.fromSQL(value?.toString() || '').toFormat('DD', {
            locale: i18n.language
          });
        }
        return '';
      }
    },
    {
      key: 'availability',
      headerName: t('usersView.list.columns.status'),
      sortable: true,
      component: StaffStatusSwitcher,
      width: '7%'
    },
    {
      key: '',
      headerName: t('usersView.list.columns.action'),
      width: '7%',
      component: EditUserIcon
    }
  ];
  return columns;
};
