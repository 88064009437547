import React from 'react';
import { Stack, CircularProgress, SxProps, CircularProgressProps } from '@mui/material';
import { Theme } from '@emotion/react';

type ProgressColor = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';

interface ProgressProps {
  sxProgress?: SxProps<Theme>;
  propsProgress?: CircularProgressProps;
  size?: string | number;
  position?: 'absolute' | 'relative' | 'fixed';
  sxRelative?: Theme;
  backgroundBlur?: 'strongly' | 'medium' | 'weakly';
  color?: ProgressColor;
}

const Progress = ({
  position = 'relative',
  propsProgress,
  sxProgress,
  sxRelative,
  size = 60,
  backgroundBlur = 'medium',
  color = 'primary'
}: ProgressProps) => {
  const absoluteSx = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '0',
    top: '0'
  };

  const relativeSx = {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '60vh',
    ...sxRelative
  };

  const fixedSx = {
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh'
  };

  const positionData = {
    absolute: absoluteSx,
    relative: relativeSx,
    fixed: fixedSx
  };

  const progressRest = propsProgress ?? {};

  const blur = {
    strongly: '1',
    medium: '0.4',
    weakly: '0.1'
  };

  const sxContainerRest = {
    ...positionData[position],
    alignItems: 'center',
    justifyContent: 'center',
    background: `rgba(255,255,255,${blur[backgroundBlur]})`,
    zIndex: '100'
  };

  return (
    <Stack sx={sxContainerRest}>
      <CircularProgress sx={sxProgress} color={color} thickness={6} size={size} {...progressRest} />
    </Stack>
  );
};

export default Progress;
