import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { StyledComponent } from '@emotion/styled';
import { AnyType } from 'src/globalTypes';
import { Box } from '@mui/material';
import { getImageUrlWithAuth } from '@utils/getImageUrlWithAuth';
import { getJwtTokenFromCurrentSession } from '@utils/getJwtTokeFromCurrentSession';
import Progress from '@shared/components/Progress';
import { SkeletonPicture } from './styles';

type OmitImageProps = 'src';

interface PictureProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  isAuth?: boolean;
  PictureComponent?: StyledComponent<
    AnyType,
    Omit<
      React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
      OmitImageProps
    >
  >;
  isLoadingAsLoader?: boolean;
}

export const Picture = forwardRef<HTMLImageElement, PictureProps>(
  (
    {
      isAuth = false,
      PictureComponent,
      src,
      width = undefined,
      height = undefined,
      isLoadingAsLoader = false,
      alt,
      style,
      className,
      onError,
      loading
    },
    ref
  ) => {
    const [isLoading, setLoading] = useState(true);
    const [isLoadingSrc, setLoadinSrc] = useState(true);
    const [source, setSource] = useState(src ?? '');

    const getIdTokenFromCurrentSession = useCallback(async () => {
      const token = await getJwtTokenFromCurrentSession();

      return token;
    }, []);

    const handleOnLoadPicture = () => setLoadinSrc(false);

    useEffect(() => {
      if (!isAuth) return;

      const updatedSourcePictureWithAuth = async () => {
        setLoading(true);

        const sessionToken = await getIdTokenFromCurrentSession();

        setSource(getImageUrlWithAuth(src, sessionToken));

        setLoading(false);
      };

      updatedSourcePictureWithAuth();
    }, []);

    const Loader = () =>
      isLoadingAsLoader ? (
        <Progress backgroundBlur="weakly" size="15px" position="absolute" />
      ) : (
        <SkeletonPicture variant="rectangular" width={width ?? '100%'} height={height ?? '100%'} />
      );

    if (isLoading) {
      return <Loader />;
    }

    if (PictureComponent) {
      return (
        <>
          <PictureComponent
            ref={ref}
            loading={loading}
            style={style}
            className={className}
            onError={onError}
            onLoad={handleOnLoadPicture}
            src={source}
            alt={alt}
          />
          {isLoadingSrc && <Loader />}
        </>
      );
    }

    return (
      <Box width="100%" height="100%" position="relative">
        <img
          ref={ref}
          loading={loading}
          className={className}
          onError={onError}
          onLoad={handleOnLoadPicture}
          src={source}
          width={width}
          height={height}
          style={style}
          alt={alt}
        />
        {isLoadingSrc && <Loader />}
      </Box>
    );
  }
);
