import { Styles } from '@utils/types';

const styles: Styles = {
  box: {
    height: '100vh',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: '700',
    lineHeight: '42px',
    textAlign: 'center'
  },
  subtitle: {
    fontWeight: '400',
    lineHeight: '23px',
    textAlign: 'center'
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    margin: '0 1.3rem'
  },
  bg: {
    height: '100vh',
    backgroundImage: 'url("/img/login-bg2.jpg")',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
};

export default styles;
