import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PageLoader, SearchAdornment, TextControl } from '@shared/components';
import { ExportButton, PersonAddButton } from '@shared/components/GenericButtons';
import { staffColumns } from '@utils/user-columns/staffColumns';
import { customerColumns } from '@utils/user-columns/customerColumns';
import {
  changeUsersSearchTerm,
  clearSelectedUsersId,
  resetSearchTerm,
  resetUserProfileData,
  selectedUserIds,
  selectUserRequestStatus,
  selectUserRole,
  selectUserSearchTerm
} from '@state/users/userSlice';
import {
  checkErrorBodyByDownload,
  checkIsStringErrorMessageBodyByDownload
} from '@utils/checkErrorBodyByDownload';
import { SomethingWentWrongGlobalMessage, UserRole } from '@helpers';
import { Nullable } from 'src/globalTypes';
import { useAppSelector } from '@hooks/useStore';
import { UserListTypeSwitcher } from '@features/admin/UserListTypeSwitcher';
import { EntityId } from '@reduxjs/toolkit';
import { useExportUsersMutation, useGetFilesDownloadLinkMutation } from '@state/apiSlice';
import { poll } from '@utils/async';
import { DownloadUrlResponse } from '@features/files/types';
import { RequestStatus } from '@utils/types';
import download from 'downloadjs';
import { UsersView } from '../../features/users-view';
import { setGlobalErrorToastMessage } from '../GlobalToast';

interface RoleListProps {
  mainRole: UserRole.ADMIN | UserRole.STAFF;
}

export const UserRoleList = ({ mainRole }: RoleListProps) => {
  const isAdmin = useMemo(() => mainRole === UserRole.ADMIN, [mainRole]);
  const selectFilterByUserRole = useAppSelector(selectUserRole);
  const selectedUserListIds = useAppSelector(selectedUserIds);
  const filterSelectedRolePermission = useMemo(
    () => (isAdmin ? selectFilterByUserRole : UserRole.CUSTOMER),
    [selectFilterByUserRole]
  );

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [exportUsers] = useExportUsersMutation();
  const [getFilesDownloadLink] = useGetFilesDownloadLinkMutation();
  const requestStatus = useAppSelector(selectUserRequestStatus);
  const isLoading = requestStatus === RequestStatus.LOADING;

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const searchInput = useAppSelector(selectUserSearchTerm);

  const [columns, setColumns] = useState(
    filterSelectedRolePermission === UserRole.CUSTOMER
      ? customerColumns(t, i18n)
      : staffColumns(t, i18n)
  );

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setSearchTerm(searchInput);
  }, [searchInput]);

  useEffect(() => {
    return () => {
      dispatch(resetSearchTerm());
    };
  }, []);

  const handleSearch = (newSearchTerm: string) => {
    dispatch(changeUsersSearchTerm(newSearchTerm));
  };

  const debouncedSearchHandler = useCallback(debounce(handleSearch, 500), []);

  const handleAddPerson = () => {
    dispatch(resetUserProfileData());
    history.push(
      `/${isAdmin ? UserRole.ADMIN : UserRole.STAFF}/add/${filterSelectedRolePermission}`
    );
  };

  const handleExportUsers = async (usersIds: EntityId[], userRole: string) => {
    setUploadInProgress(true);
    const downloadId = await exportUsers({ usersIds, userRole }).unwrap();

    if (!downloadId) {
      setUploadInProgress(false);
      return;
    }

    let downloadLinkResponse: Nullable<DownloadUrlResponse> = null;

    try {
      downloadLinkResponse = await poll<DownloadUrlResponse>(
        () => getFilesDownloadLink(downloadId).unwrap(),
        (response: DownloadUrlResponse) => response.status === 'Ready' && !!response.url,
        2000
      );
    } catch (error) {
      let errorMessage: string = SomethingWentWrongGlobalMessage;
      const isNotAllowedDownloadBody = checkErrorBodyByDownload(error);
      if (isNotAllowedDownloadBody) {
        const {
          data: { error: errorBodyMessageByDownload }
        } = error;
        const isStringErrorBodyMessage = checkIsStringErrorMessageBodyByDownload(
          errorBodyMessageByDownload
        );
        if (isStringErrorBodyMessage) {
          errorMessage = errorBodyMessageByDownload;
        }
      }
      setGlobalErrorToastMessage(errorMessage);
      downloadLinkResponse = null;
      setUploadInProgress(false);
    }

    if (!downloadLinkResponse) {
      setUploadInProgress(false);
      return;
    }

    fetch(downloadLinkResponse.url as string, {
      method: 'GET'
    })
      .then((res) => res.blob())
      .then((blob) => {
        download(blob, `${new Date().toLocaleString()}.csv`);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error) => {
        let errorMessage: string = SomethingWentWrongGlobalMessage;
        const isNotAllowedDownloadBody = checkErrorBodyByDownload(error);
        if (isNotAllowedDownloadBody) {
          const {
            data: { error: errorBodyMessageByDownload }
          } = error;
          const isStringErrorBodyMessage = checkIsStringErrorMessageBodyByDownload(
            errorBodyMessageByDownload
          );
          if (isStringErrorBodyMessage) {
            errorMessage = errorBodyMessageByDownload;
          }
        }
        setGlobalErrorToastMessage(errorMessage);
        setUploadInProgress(false);
      })
      .finally(() => {
        setUploadInProgress(false);
        dispatch(clearSelectedUsersId());
      });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <UserListTypeSwitcher
          setColumns={setColumns}
          isAdmin={isAdmin}
          selectedUserRole={filterSelectedRolePermission}
        />
        <Stack direction="row">
          <TextControl
            id="search"
            name="search"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
              debouncedSearchHandler(event.target.value);
            }}
            placeholder={
              filterSelectedRolePermission === UserRole.CUSTOMER
                ? t('admin.searchCustomer')
                : t('admin.searchStaff')
            }
            startAdornment={<SearchAdornment />}
            sx={{ width: '50vh', mr: 2 }}
          />
          <ExportButton
            onClick={() => handleExportUsers(selectedUserListIds, filterSelectedRolePermission)}
            sx={{ mr: 2, width: 160 }}
          />
          <PersonAddButton onClick={handleAddPerson} sx={{ width: 160 }}>
            {filterSelectedRolePermission === UserRole.CUSTOMER
              ? t('admin.addCustomer')
              : t('admin.addStaff')}
          </PersonAddButton>
        </Stack>
      </Stack>
      <UsersView selectedUserRole={filterSelectedRolePermission} columns={columns} />
      <PageLoader open={uploadInProgress || isLoading} />
    </>
  );
};
