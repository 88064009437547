/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { AlertColor, Typography } from '@mui/material';
import { Transition } from './Transition/TransitionModal';
import { DialogActionsCustom, DialogContentCustom, ErrorAlert } from './styles';

export interface ConfirmationModalProps {
  isOpen: boolean;
  body: string;
  successMessage: string;
  title?: string;
  isHiddenConfirm?: boolean;
  severity?: AlertColor;

  handleClose: () => void;

  handleConfirm?: () => void;
  handleCancel?: () => void;
}

export const ConfirmationModal = ({
  isOpen,
  handleClose,
  title,
  body,
  handleConfirm,
  handleCancel,
  isHiddenConfirm = false,
  severity,
  successMessage
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContentCustom>
        <DialogContentText id="alert-dialog-description">
          <ErrorAlert severity={severity ?? 'error'}>
            <Typography variant="h6">{body}</Typography>
          </ErrorAlert>
        </DialogContentText>
      </DialogContentCustom>
      <DialogActionsCustom>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
            handleCancel?.();
          }}
        >
          {t('confirmation-modal.disagree')}
        </Button>

        {!isHiddenConfirm && (
          <Button
            variant="contained"
            onClick={() => {
              handleConfirm?.();
              handleCancel?.();
            }}
          >
            {successMessage}
          </Button>
        )}
      </DialogActionsCustom>
    </Dialog>
  );
};
