import React, { useContext } from 'react';
import { Avatar } from '@mui/material';
import { AuthContext } from '@contexts';
import { stringAvatar } from './utils';

export const UserAvatar = () => {
  const { user } = useContext(AuthContext);
  const fullName = `${user?.attributes?.given_name || ''} ${user?.attributes?.family_name || ''}`;
  return <Avatar {...stringAvatar(fullName)} />;
};

export const CustomAvatar = ({ fullName }: { fullName: string }) => {
  return <Avatar {...stringAvatar(fullName)} />;
};
