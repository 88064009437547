import React, { useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { UploadFilesButton } from '@shared/components/GenericButtons';
import { useAppSelector } from '@hooks/useStore';
import { UserRole } from '@helpers';
import { selectUserRole } from '@state/users/userSlice';
import { UppyFilesDialog } from '@features/files/upload/UppyFilesDialog';
import { ReturnBackIcon } from '@features/admin/styled';
import { returnBackToUserList } from '@features/admin/utils/returnBackToUserList';
import { CustomerForm } from '../CustomerForm';
import { StaffForm } from '../StaffForm';

export const UserRoleDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const activeListFilter = useAppSelector(selectUserRole);

  const currentForm =
    activeListFilter === UserRole.STAFF ? (
      <StaffForm headerText={t('admin.staffDetails')} btnText={t('admin.editStaff')} />
    ) : (
      <CustomerForm headerText={t('admin.customerDetails')} btnText={t('admin.editCustomer')} />
    );
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();

  const handleOpen = () => {
    setAnchorEl(ref.current || null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const returnBack = () => {
    history.goBack();
    returnBackToUserList(dispatch);
  };

  return (
    <Stack sx={{ minHeight: '80vh' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mr: 3 }}>
        <ReturnBackIcon onClick={returnBack} />
        {activeListFilter === UserRole.CUSTOMER && (
          <UploadFilesButton ref={ref} onClick={handleOpen}>
            <CloudUploadIcon />
          </UploadFilesButton>
        )}
      </Stack>
      {currentForm}
      <UppyFilesDialog anchorEl={anchorEl} onClose={handleClose} />
    </Stack>
  );
};
