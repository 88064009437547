import * as T from './types';

class Analytics {
  authLogin(payload: T.AuthPayload) {
    this.sendCustomEvent('auth_login', payload);
  }

  authAutoLogin(payload: T.AuthPayload) {
    this.sendCustomEvent('auth_auto_login', payload);
  }

  authLogOut(payload: T.AuthLogOut) {
    this.sendCustomEvent('auth_logout', payload);
  }

  authSignUp(payload: T.AuthPayload) {
    this.sendCustomEvent('auth_sign_up', payload);
  }

  authRecoveryPassword(payload: T.AuthPayload) {
    this.sendCustomEvent('auth_recovery_password', payload);
  }

  userDownloadFiles(payload: T.UserDownloadFiles) {
    this.sendCustomEvent('user_download_files', payload);
  }

  userUploadFiles(payload: T.UserUploadFiles) {
    this.sendCustomEvent('user_upload_files', payload);
  }

  userShareFiles(payload: T.UserShareFiles) {
    this.sendCustomEvent('user_share_files', payload);
  }

  userCreateAlbum(payload: T.UserCreateAlbum) {
    this.sendCustomEvent('user_create_album', payload);
  }

  userUpgradePlan(payload: T.PaywallViewEvent) {
    this.sendCustomEvent('user_upgrade_plan', payload);
  }

  userDeleteFiles(payload: T.UserDeleteFile) {
    // no connect descktop
    this.sendCustomEvent('user_delete_files', payload);
  }

  userRestoreFiles(payload: T.UserRestoreFile) {
    // no connect descktop
    this.sendCustomEvent('user_restore_files', payload);
  }

  userStartLookingFile(payload: T.UserLookingFile) {
    // no connect descktop
    this.sendCustomEvent('user_start_looking_file', payload);
  }

  userStopLookingFile(payload: T.UserLookingFile) {
    // no connect descktop
    this.sendCustomEvent('user_stop_looking_file', payload);
  }

  userMinimizeApp(payload: T.PaywallViewEvent) {
    this.sendCustomEvent('user_minimize_app', payload);
  }

  unexpectedError(payload: any) {
    this.sendCustomEvent('unexpected_error', payload);
  }

  expectedError(payload: T.PaywallViewEvent) {
    this.sendCustomEvent('expected_error', payload);
  }

  purchase(payload: T.PurchaseEvent) {
    this.sendCustomEvent('purchase', payload);
  }

  checkoutView(payload: T.CheckoutViewEvent) {
    this.sendCustomEvent('begin_checkout', payload);
  }

  contactUsClick() {
    this.sendCustomEvent('contact_us_click', {});
  }

  // eslint-disable-next-line class-methods-use-this
  private sendCustomEvent(eventName: string, payload: object) {
    window.dataLayer?.push({
      event: eventName,
      // event_name: eventName,
      // event_id: `${this.userSub}_${Date.now()}`,
      eventModel: payload
    });
  }

  private checkGtmAndSendEvent(eventName: string, payload: object) {
    if (window.dataLayer) {
      this.sendCustomEvent(eventName, payload);
    } else {
      setTimeout(() => {
        this.checkGtmAndSendEvent(eventName, payload);
      }, 1000);
    }
  }
}

export const analytics = new Analytics();
