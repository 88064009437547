import { i18n } from 'i18next';
import { DateTime } from 'luxon';

export const getDateFromSQLI18nLanguage = (
  dateData: { date: string; format: string; i18n: i18n | undefined } = {
    date: '',
    format: 'DD',
    i18n: undefined
  }
) =>
  DateTime.fromSQL(dateData.date?.toString() || '').toFormat(dateData.format, {
    locale: dateData?.i18n?.language
  });
