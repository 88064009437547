import { CustomerSubscriptionPlan, LocalStorageKeys, PAGES, UserRole } from '@helpers';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { $BaseApi, LocalStorageService } from '@services';
import { RootState } from '@store';
import { RequestStatus } from '@utils/types';
import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { setGlobalSuccessToastMessage, setGlobalErrorToastMessage } from '@common';
import { analytics } from '@analytics';
import {
  AuthState,
  ErrorBody,
  LoginActionPayload,
  PasswordConfirmParams,
  SignUpConfirmParams,
  SignUpParams
} from './types';
import { changeRequestStatus, changeUserListFilter, clearSelectedUsersId } from './users/userSlice';

const initialState = {
  loading: false
} as AuthState;

export interface ResetPasswordInviteStatusBody {
  code: string;
  password: string;
}

export const sendResetPasswordByInviteStatus = createAsyncThunk<AxiosError<ErrorBody>, string>(
  'user/reset-password',
  async (email, { rejectWithValue }) => {
    try {
      const response = await $BaseApi.get(`/users/${email}/reset-password`);
      setGlobalSuccessToastMessage(
        'Successfully sent new authorization code!',
        'Please check your email'
      );
      return response.data;
    } catch (err) {
      setGlobalErrorToastMessage('Cannot send new authorization code.', 'Please try again later');

      const error = err as AxiosError<ErrorBody['data']>;
      return rejectWithValue(error?.response?.data?.error ?? '');
    }
  }
);

export const changePasswordByInviteStatus = createAsyncThunk<
  AxiosError<ErrorBody>,
  { changePasswordData: ResetPasswordInviteStatusBody; email: string }
>('user/forgot-password-data', async (data, { rejectWithValue }) => {
  const { email, changePasswordData } = data;

  const body: {
    payload: ResetPasswordInviteStatusBody;
  } = { payload: changePasswordData };

  try {
    const response = await $BaseApi.post(`/users/${email}/reset-password`, body);
    return response.data;
  } catch (err) {
    const error = err as AxiosError<ErrorBody['data']>;
    return rejectWithValue(error?.response?.data?.error ?? '');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signUpInit: (state: AuthState) => {
      return {
        ...state,
        error: null,
        loading: true
      };
    },
    signUpSuccess: (state: AuthState, action: PayloadAction<string>) => {
      return {
        ...state,
        username: action.payload,
        error: null,
        loading: false
      };
    },
    signUpFailure: (state: AuthState) => {
      return {
        ...state,
        loading: false
      };
    },
    signUpConfirmInit: (state: AuthState) => {
      return {
        ...state,
        error: null,
        loading: true
      };
    },
    signUpConfirmSuccess: (state: AuthState) => {
      return {
        ...state,
        error: null,
        loading: false
      };
    },
    signUpConfirmFailure: (state: AuthState, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    },
    signInInit: (state: AuthState) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    },
    signInSuccess: (state: AuthState) => {
      Auth.currentAuthenticatedUser().then((data) => {
        LocalStorageService.set(LocalStorageKeys.USER_ID, data.username);
      });
      return {
        ...state,
        loading: false,
        error: null
      };
    },
    signInFailure: (state: AuthState, action: PayloadAction<LoginActionPayload>) => {
      return {
        ...state,
        error: action.payload.error,
        credentials: action.payload.credentials,
        signedIn: false,
        loading: false
      };
    },
    forgotPasswordReset: (state: AuthState) => {
      return {
        ...state,
        username: '',
        forgotPasswordStep: 'init',
        forgotPasswordError: null
      };
    },
    forgotPasswordSuccess: (state: AuthState, action: PayloadAction<string>) => {
      return {
        ...state,
        username: action.payload,
        forgotPasswordStep: 'confirm',
        forgotPasswordError: null
      };
    },
    forgotPasswordFailure: (state: AuthState, action: PayloadAction<string>) => {
      return {
        ...state,
        forgotPasswordError: action.payload
      };
    },
    forgotPasswordSubmitSuccess: (state: AuthState) => {
      return {
        ...state,
        forgotPasswordStep: 'success',
        forgotPasswordError: null
      };
    },
    forgotPasswordSubmitFailure: (state: AuthState, action: PayloadAction<string>) => {
      return {
        ...state,
        forgotPasswordError: action.payload
      };
    },
    signOut: () => {
      localStorage.removeItem('userId');

      return initialState;
    },
    resetAuthError: (state: AuthState) => {
      return {
        ...state,
        error: ''
      };
    },
    resetLoading: (state: AuthState) => {
      return {
        ...state,
        loading: false
      };
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(sendResetPasswordByInviteStatus.fulfilled, (state: AuthState) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(sendResetPasswordByInviteStatus.rejected, (state: AuthState) => {
        state.loading = false;
      })

      .addCase(changePasswordByInviteStatus.fulfilled, (state: AuthState) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(changePasswordByInviteStatus.pending, (state: AuthState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePasswordByInviteStatus.rejected, (state: AuthState) => {
        state.loading = false;
      })
});

export const {
  signUpInit,
  signUpSuccess,
  signUpFailure,
  signUpConfirmInit,
  signUpConfirmSuccess,
  signUpConfirmFailure,
  signInInit,
  signInSuccess,
  signInFailure,
  forgotPasswordReset,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordSubmitSuccess,
  forgotPasswordSubmitFailure,
  signOut,
  resetAuthError,
  resetLoading
} = authSlice.actions;
export default authSlice.reducer;

export const selectAuth = (state: RootState) => state.auth;

export const signUp = (params: SignUpParams) => (dispatch: Dispatch) => {
  const { username, password, firstname, lastname, history } = params;

  dispatch(signUpInit());

  Auth.signUp({
    username,
    password,
    clientMetadata: {
      subscription: CustomerSubscriptionPlan.GB1,
      account_type: UserRole.CUSTOMER
    },
    attributes: {
      given_name: firstname,
      family_name: lastname
    }
  })
    .then(() => {
      history.push('/signup/confirm', {
        email: username
      });
      dispatch(signUpSuccess(username));
    })
    .catch((error) => {
      analytics.authSignUp({ user_email: username, status: 'failure', error: error.message });
      setGlobalErrorToastMessage('Error', error.message);
      history.push('/signup');
      dispatch(signUpFailure());
    });
};

export const resendConfirmationCode = async (username: string) => {
  try {
    await Auth.resendSignUp(username);
    setGlobalSuccessToastMessage(
      'Successfully sent new authorization code!',
      'Please check your email'
    );
  } catch (err) {
    setGlobalErrorToastMessage('Cannot send new authorization code.', 'Please try again later.');
    throw err;
  }
};

export const signUpConfirm = (params: SignUpConfirmParams, isPendingStatus: boolean) => (
  dispatch: Dispatch
) => {
  const { username, code, history } = params;

  dispatch(signUpConfirmInit());

  return Auth.confirmSignUp(username, code)
    .then(() => {
      if (isPendingStatus) {
        analytics.authSignUp({ user_email: username, status: 'success' });
        setGlobalSuccessToastMessage(
          'Congratulations! You have successfully set up your account.',
          'Now you can login.'
        );
      }

      history.push(isPendingStatus ? PAGES.Login : '/signup/complete');
      dispatch(signUpConfirmSuccess());
    })
    .catch((error) => {
      dispatch(signUpConfirmFailure(error.message));
      throw error;
    });
};

export const signIn = (username: string, password: string, history: any) => (
  dispatch: Dispatch
) => {
  dispatch(signInInit());

  return Auth.signIn(username, password)
    .then((user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(signInFailure({ credentials: user }));
        history.push('/set-password', {
          email: username.toLowerCase(),
          isTemporaryPassword: true,
          code: password
        });
      } else if (user.error) {
        analytics.authLogin({
          user_email: username,
          status: 'failure',
          error: user.error?.message
        });
        dispatch(signInFailure({ error: user.error }));
      } else {
        dispatch(signInSuccess());
        analytics.authLogin({ user_email: username, status: 'success' });

        const Role: UserRole = user?.getSignInUserSession()?.getIdToken().payload[
          'custom:account_type'
        ];

        let userUrl = PAGES.Home;

        switch (Role) {
          case UserRole.ADMIN:
            dispatch(changeUserListFilter(UserRole.STAFF));
            userUrl = PAGES.Admin;
            break;
          case UserRole.STAFF:
            dispatch(changeUserListFilter(UserRole.CUSTOMER));
            userUrl = PAGES.Staff;
            break;
          default:
            break;
        }
        dispatch(changeRequestStatus(RequestStatus.IDLE));
        dispatch(clearSelectedUsersId());
        history.push(userUrl);
      }
    })
    .catch((error) => {
      analytics.authLogin({
        user_email: username,
        status: 'failure',
        error: error?.message
      });
      dispatch(signInFailure({ error: error.message }));
    });
};

export const forgotPassword = (username: string) => (dispatch: Dispatch) => {
  return Auth.forgotPassword(username)
    .then(() => dispatch(forgotPasswordSuccess(username)))
    .catch((error) => {
      dispatch(forgotPasswordFailure(error.message));
      throw error;
    });
};

export const forgotPasswordSubmit = (params: PasswordConfirmParams) => (dispatch: Dispatch) => {
  const { username, authCode, newPassword, history } = params;

  Auth.forgotPasswordSubmit(username, authCode, newPassword)
    .then(() => {
      dispatch(forgotPasswordSubmitSuccess());
      setGlobalSuccessToastMessage('Password successfully changed!');
      analytics.authRecoveryPassword({
        user_email: username,
        status: 'success'
      });
      history.push(PAGES.Login);
    })
    .catch((error) => {
      analytics.authRecoveryPassword({
        user_email: username,
        status: 'failure',
        error: error.code
      });
      switch (error.code) {
        case 'CodeMismatchException':
          history.push(PAGES.RecoveringPassword.Code);
          break;
        case 'UserLambdaValidationException':
        case 'LimitExceededException':
          setGlobalErrorToastMessage(
            'Cannot send new authorization code.',
            'Please try again later.'
          );
          history.push(PAGES.Login);
          break;
        default:
          break;
      }
      dispatch(forgotPasswordSubmitFailure(error.message));
    });
};

export const changePassword = (
  username: string,
  oldPassword: string,
  newPassword: string,
  history: any
) => (dispatch: Dispatch) => {
  Auth.signIn(username, oldPassword)
    .then((authResponse) => {
      if (authResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(authResponse, newPassword)
          .then(async () => {
            await signIn(username, newPassword, history)(dispatch);
            window.location.reload();
          })
          .catch((awsResponseError) => {
            const errorMessage =
              awsResponseError instanceof Error ? awsResponseError?.message ?? '' : '';

            setGlobalErrorToastMessage(errorMessage);
          });
      } else {
        // other situations
      }
    })
    .catch((awsResponseError) => {
      const errorMessage = awsResponseError instanceof Error ? awsResponseError?.message ?? '' : '';

      setGlobalErrorToastMessage(errorMessage);
    });
};
