import styled from 'styled-components';
import { breakpoints } from '@shared/theme';

export const SearchContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  align-items: center;
  border-radius: 8px;
  background: #f3f3f3;
`;

export const SearchAdornment = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  border: none;
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: #a8a8a8;
  }
  @media screen and (max-width: ${breakpoints.values.md}px) {
    font-size: 14px;
  }
`;
