import { BillingPeriod } from '@helpers';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { $AuthApi } from '@services';
import { UserPlan } from '@state/trash/types';
import { ErrorBody } from '@state/types';
import { RootState } from '@store';
import { RequestStatus } from '@utils/types';
import { AxiosError } from 'axios';
import { Nullable } from 'src/globalTypes';
import { getSortedUpgradeUserPlansByPrice } from './utils';

export const getUserPlans = createAsyncThunk<UserPlan[], void>(
  'get/plans',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $AuthApi.get<{ message: string; subscriptions: UserPlan[] }>(
        '/subscriptions'
      );

      return response.data.subscriptions;
    } catch (err) {
      const error = err as AxiosError<ErrorBody['data']>;
      return rejectWithValue(error?.response?.data?.error ?? '');
    }
  }
);

export const createCheckoutSession = createAsyncThunk<undefined, UserPlan['id']>(
  'create/checkoutSession',
  async (subscription_id, { rejectWithValue }) => {
    try {
      const { data } = await $AuthApi.post<{ message: string; url: string }>(
        '/payments/stripe/create-checkout-session',
        {
          payload: { subscription_id }
        }
      );
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', data.url);
      linkElement.click();

      return;
    } catch (err) {
      const error = err as AxiosError<ErrorBody['data']>;
      // eslint-disable-next-line consistent-return
      return rejectWithValue(error?.response?.data?.error ?? '');
    }
  }
);

export const managePlanRedirect = createAsyncThunk<undefined, void>(
  'redirect/managePlan',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await $AuthApi.get<{ message: string; url: string }>(
        'payments/stripe/customer-portal'
      );
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', data.url);
      linkElement.click();

      return;
    } catch (err) {
      const error = err as AxiosError<ErrorBody['data']>;
      // eslint-disable-next-line consistent-return
      return rejectWithValue(error?.response?.data?.error ?? '');
    }
  }
);

interface UpgradePlanInitalState {
  data: Nullable<UserPlan[]>;
  typeOfBillingPeriod: BillingPeriod;
  status: RequestStatus;
  isLoaded: boolean;
  error: Nullable<string>;
  isOpenModal: boolean;
}

const initialState: UpgradePlanInitalState = {
  data: [],
  typeOfBillingPeriod: BillingPeriod.MONTHLY,
  status: RequestStatus.IDLE,
  isLoaded: true,
  error: null,
  isOpenModal: false
};

const upgradePlanSlice = createSlice({
  name: 'upgradePlan',
  initialState,
  reducers: {
    changeBillingPeriod: (state, { payload }: PayloadAction<BillingPeriod>) => {
      state.typeOfBillingPeriod = payload;
    },
    setOpenUpgradeModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenModal = payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUserPlans.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = RequestStatus.SUCCEEDED;

        state.isLoaded = true;
      })
      .addCase(getUserPlans.pending, (state) => {
        state.status = RequestStatus.LOADING;
        state.error = '';

        state.isLoaded = false;
      })
      .addCase(getUserPlans.rejected, (state, { payload }) => {
        state.status = RequestStatus.FAILED;
        state.error = payload as string;

        state.isLoaded = true;
      })

      .addCase(createCheckoutSession.fulfilled, (state) => {
        state.status = RequestStatus.SUCCEEDED;
        state.isLoaded = true;
      })
      .addCase(createCheckoutSession.pending, (state) => {
        state.status = RequestStatus.LOADING;
        state.error = '';

        state.isLoaded = false;
      })
      .addCase(createCheckoutSession.rejected, (state, { payload }) => {
        state.status = RequestStatus.FAILED;
        state.error = payload as string;
        state.isLoaded = true;
      })

      .addCase(managePlanRedirect.fulfilled, (state) => {
        state.status = RequestStatus.SUCCEEDED;
        state.isLoaded = true;
      })
      .addCase(managePlanRedirect.pending, (state) => {
        state.status = RequestStatus.LOADING;
        state.error = '';
        state.isLoaded = false;
      })
      .addCase(managePlanRedirect.rejected, (state, { payload }) => {
        state.status = RequestStatus.FAILED;
        state.error = payload as string;
        state.isLoaded = true;
      })
});

// actions
export const { changeBillingPeriod, setOpenUpgradeModal } = upgradePlanSlice.actions;

export const selectUserUpgradePlan = (state: RootState) => state.upgradePlan;
export const selectUpgradePlans = (state: RootState) => state.upgradePlan.data;
export const selectUpgradeLoading = (state: RootState) => state.upgradePlan.isLoaded;
export const selectBillingPeriod = (state: RootState) => state.upgradePlan.typeOfBillingPeriod;
export const selectUpgradePlanModal = (state: RootState) => state.upgradePlan.isOpenModal;

export const selectFilteredUserPlanBySubscription = createSelector(
  selectUpgradePlans,
  selectBillingPeriod,
  (plans, billingPeriod) =>
    plans
      ? getSortedUpgradeUserPlansByPrice(
          plans?.filter(({ billing_period }) => billingPeriod === billing_period)
        )
      : []
);
export default upgradePlanSlice.reducer;
