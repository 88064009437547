import React from 'react';
import { Stack, Typography } from '@mui/material';

import { User } from '@state/types';
import { ListEntity } from '@shared/components/list-view/types';
import { UserAvatar, UserAvatarWrapper } from '@common';

export const UserInfoColumn = (data: ListEntity) => {
  const { given_name: firstName, family_name: lastName } = data as User;
  const fullName = `${firstName} ${lastName}`;
  return (
    <Stack direction="row" alignItems="center" sx={{ minWidth: 0 }}>
      <UserAvatarWrapper>
        <UserAvatar />
      </UserAvatarWrapper>
      <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {fullName}
      </Typography>
    </Stack>
  );
};
