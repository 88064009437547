export interface SimpleUploadError {
  reason: string;
}

export interface SimpleUploadImageInfo extends Pick<File, 'name' | 'size' | 'type'> {
  file: File;
}
export interface SimpleUploadSuccess {
  base64URL: string;
  imageInfo: SimpleUploadImageInfo;
}
