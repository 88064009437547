import React from 'react';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps<Theme>;
}

export const Logo = ({ sx }: Props) => {
  return <Box component="img" src="/img/logo.svg" sx={sx} />;
};
