import React from 'react';
import { styled, SvgIcon, SvgIconProps } from '@mui/material';

const Svg = styled(SvgIcon)({
  width: 20,
  height: 20
});

export const SortDouble = (props: SvgIconProps = { sx: { fontSize: '1.25rem' } }) => {
  return (
    <Svg viewBox="0 0 9 16" {...props}>
      <path
        d="M3.53546 1.62213C3.84717 1.11022 4.59033 1.11022 4.90204 1.62213L7.1318 5.28393C7.45643 5.81704 7.07268 6.5 6.44851 6.5H1.98899C1.36482 6.5 0.981075 5.81704 1.3057 5.28393L3.53546 1.62213Z"
        fill="#183857"
      />
      <path
        d="M4.90181 14.3779C4.5901 14.8898 3.84695 14.8898 3.53524 14.3779L1.30548 10.7161C0.98085 10.183 1.36459 9.5 1.98877 9.5L6.44828 9.5C7.07246 9.5 7.4562 10.183 7.13157 10.7161L4.90181 14.3779Z"
        fill="#183857"
      />
    </Svg>
  );
};

export const SortDown = (props: SvgIconProps = { sx: { fontSize: '0.325rem' } }) => {
  return (
    <Svg viewBox="-5 0 16 6" {...props}>
      <path d="M3.90181 5.37787C3.5901 5.88978 2.84695 5.88978 2.53524 5.37787L0.305476 1.71607C-0.0191503 1.18296 0.364592 0.500001 0.988765 0.500001L5.44828 0.500001C6.07246 0.500001 6.4562 1.18296 6.13157 1.71607L3.90181 5.37787Z" />
    </Svg>
  );
};

export const SortUp = (props: SvgIconProps = { sx: { fontSize: '0.325rem' } }) => {
  return (
    <Svg viewBox="-5 0 16 6" {...props}>
      <path d="M2.53546 0.622126C2.84717 0.110221 3.59033 0.110221 3.90204 0.622126L6.1318 4.28393C6.45643 4.81704 6.07268 5.5 5.44851 5.5H0.988991C0.364818 5.5 -0.0189251 4.81704 0.305701 4.28393L2.53546 0.622126Z" />
    </Svg>
  );
};
