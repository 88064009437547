import { DocumentContentType } from '@helpers';

export const supportedImages = [
  DocumentContentType.IMAGE_JPEG,
  DocumentContentType.IMAGE_JPG,
  DocumentContentType.IMAGE_PNG,
  DocumentContentType.IMAGE_TIFF,
  DocumentContentType.IMAGE_TIF
];
export const supportedVideos = [
  DocumentContentType.VIDEO_MP4,
  DocumentContentType.VIDEO_MPEG,
  DocumentContentType.VIDEO_WMV,
  DocumentContentType.VIDEO_QUICKTIME,
  DocumentContentType.VIDEO_AVI
];

export const nativePlayerSupportedVideos = [
  DocumentContentType.VIDEO_MP4,
  DocumentContentType.VIDEO_MPEG
];
export const supportedAudios = [DocumentContentType.AUDIO_MPEG, DocumentContentType.AUDIO_OGG];
export const supportedDocuments = [
  DocumentContentType.TEXT_PLAIN,
  DocumentContentType.APPLICATION_MSWORD,
  DocumentContentType.APPLICATION_POWERPOINT,
  DocumentContentType.APPLICATION_EXCEL,
  DocumentContentType.TEXT_HTML
];

export const isImageType = (type: DocumentContentType) => supportedImages.includes(type);
export const isVideoType = (type: DocumentContentType) => supportedVideos.includes(type);
export const isNativePlayerSupported = (type: DocumentContentType) =>
  nativePlayerSupportedVideos.includes(type);
export const isAudioType = (type: DocumentContentType) => supportedAudios.includes(type);
export const isPdf = (type: DocumentContentType) => type === DocumentContentType.APPLICATION_PDF;
