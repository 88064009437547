import React, { StrictMode } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { ApolloProvider } from '@apollo/client';
import { useAppSelector } from '@hooks/useStore';
import { AuthProvider, PageLoader } from '@shared/components';
import theme from '@shared/theme';
import { GlobalToast } from '@common';
import client from 'src/apollo/client';
import Routing from './routing/routing';

const App = () => {
  const loading = useAppSelector((state) => state.auth.loading);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <ApolloProvider client={client}>
          <StrictMode>
            <PageLoader open={loading} />
            <GlobalToast />
            {!loading && <Routing />}
          </StrictMode>
        </ApolloProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
