import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
  MailOutlined,
  SearchOutlined
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)({
  backgroundColor: '#fafbfc'
});

const StyledInputEndAdornment = styled(InputAdornment)({
  position: 'absolute',
  right: 16
});

const StyledInputStartAdornment = styled(InputAdornment)({
  position: 'relative',
  left: 16
});

export const SearchAdornment = () => {
  return (
    <StyledInputStartAdornment position="start">
      {/* TODO Remove button here */}
      <StyledIconButton edge="start" disableRipple tabIndex={-1}>
        <SearchOutlined
          sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.primary, opacity: 0.5 }}
        />
      </StyledIconButton>
    </StyledInputStartAdornment>
  );
};

export const EmailAdornment = () => {
  return (
    <StyledInputEndAdornment position="end">
      {/* TODO Remove button here */}
      <StyledIconButton edge="end" disableRipple tabIndex={-1}>
        <MailOutlined
          sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.primary, opacity: 0.5 }}
        />
      </StyledIconButton>
    </StyledInputEndAdornment>
  );
};
// TODO: make component configurable by position
export const EmailStartAdornment = () => {
  return (
    <StyledInputStartAdornment position="start">
      {/* TODO Remove button here */}
      <StyledIconButton edge="start" disableRipple tabIndex={-1}>
        <MailOutlined
          sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.primary, opacity: 0.5 }}
        />
      </StyledIconButton>
    </StyledInputStartAdornment>
  );
};

interface IPasswordProps {
  show: boolean;
  onClick: () => void;
}

export const PasswordAdornment = ({ onClick, show }: IPasswordProps) => {
  return (
    <StyledInputEndAdornment position="end">
      <StyledIconButton
        onClick={onClick}
        onMouseDown={(e) => e.preventDefault()}
        edge="end"
        disableRipple
        tabIndex={-1}
      >
        {/* TODO Update with new icons */}
        {show ? (
          <VisibilityOffOutlined
            sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.primary, opacity: 0.5 }}
          />
        ) : (
          <VisibilityOutlined
            sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.primary, opacity: 0.5 }}
          />
        )}
      </StyledIconButton>
    </StyledInputEndAdornment>
  );
};
// TODO: make component configurable by position
export const PasswordStartAdornment = ({ onClick, show }: IPasswordProps) => {
  return (
    <StyledInputStartAdornment position="start">
      <StyledIconButton
        onClick={onClick}
        onMouseDown={(e) => e.preventDefault()}
        edge="start"
        disableRipple
        tabIndex={-1}
      >
        {/* TODO Update with new icons */}
        {show ? (
          <VisibilityOffOutlined
            sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.primary, opacity: 0.5 }}
          />
        ) : (
          <VisibilityOutlined
            sx={{ fontSize: '1rem', color: (theme) => theme.palette.text.primary, opacity: 0.5 }}
          />
        )}
      </StyledIconButton>
    </StyledInputStartAdornment>
  );
};
