import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '@contexts';
import { PAGES } from '@helpers';

const PrivateRoute = ({ children, comp: Component, ...rest }: any) => {
  const { user } = useContext(AuthContext);

  return <Route {...rest}>{!user ? <Redirect to={PAGES.Login} /> : children}</Route>;
};
export default PrivateRoute;
