import { DocumentContentType } from '@helpers';

export class FileService {
  static TypeImageOfProfilePhoto = [
    DocumentContentType.IMAGE_JPEG,
    DocumentContentType.IMAGE_JPG,
    DocumentContentType.IMAGE_PNG
  ];

  static uploadPhotoSize = 2 * 1024 * 1024; // 2mb

  static async dataURLtoBlob(dataURL: string): Promise<Blob> {
    const blob = await fetch(dataURL)
      .then((data) => data.blob())
      .then((blobData) => blobData);
    return blob;
  }

  static readBlob(blob: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', reject);
      reader.readAsDataURL(blob);
    });
  }

  static isInvalidFormat(file: File) {
    return !FileService.TypeImageOfProfilePhoto.includes(file.type as DocumentContentType);
  }

  static isInvalidSize(file: File) {
    return file.size > FileService.uploadPhotoSize;
  }

  static downloadFilesByUrl(url: string) {
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', `${new Date().toLocaleString()}.zip`);
    linkElement.click();
  }
}
