import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const ChangeImageIcon = () => {
  /* TODO: implement change avatar modal */
  const onClick = () => {};

  return (
    <AddCircleOutlineIcon
      cursor="pointer"
      onClick={onClick}
      sx={{ backgroundColor: '#FFFFFF', color: '#025EF9', borderRadius: '50%' }}
    />
  );
};
