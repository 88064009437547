import React, { memo, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { Checkbox, Stack, styled } from '@mui/material';
import classNames from 'classnames';

import { DragTypes } from '@utils/types';
import { useAppSelector } from '@hooks/useStore';
import { ListViewColumn, ListViewHeadColumn } from './ListColumn';
import { ListColumnProps, ListEntity, ListHeadRowProps, ListRowProps } from './types';

const ListRow = styled(Stack)(({ theme }) => ({
  position: 'relative',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 72,
  minWidth: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '&:hover': {
    cursor: 'pointer'
  },
  '&.selected': {
    backgroundColor: '#e2ebfa'
  },
  '&.isOver': {
    border: `2px dashed ${theme.palette.primary.main}`
  },
  '&.selected:before': {
    content: 'none'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: theme.spacing(2),
    right: theme.spacing(2),
    bottom: 0,
    height: 1,
    width: '100%',
    borderBottom: '1px solid #f5f5f5'
  }
}));

const ListHeadRow = styled(ListRow)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  minHeight: 56,
  zIndex: 2,
  backgroundColor: theme.palette.background.default
}));

export const ListViewHeadRow = memo(
  ({ columns, onRowSelect, onSortChange, entitySelectors }: ListHeadRowProps) => {
    const allRowIds = useAppSelector(entitySelectors.ids);
    const selectedRowIds = useAppSelector(entitySelectors.selectedIds);
    const activeSort = useAppSelector(entitySelectors.sort);
    const allRowsSelected = Boolean(allRowIds.length) && allRowIds.length === selectedRowIds.length;
    const someRowsSelected = !!selectedRowIds.length && !allRowsSelected;

    const handleSelectAll = ({
      currentTarget: { checked }
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (someRowsSelected) {
        onRowSelect(allRowIds, false);
      } else {
        onRowSelect(allRowIds, checked);
      }
    };

    return (
      <ListHeadRow>
        <Checkbox
          onChange={handleSelectAll}
          checked={allRowsSelected}
          indeterminate={someRowsSelected}
          disableRipple
        />
        {columns.map((column, idx) => (
          <ListViewHeadColumn
            key={`header-${idx}`}
            onSortChange={onSortChange}
            column={column}
            activeSort={activeSort}
          />
        ))}
      </ListHeadRow>
    );
  },
  (prevProps, nextProps) => prevProps.columns === nextProps.columns
);

export const ListViewRow = ({
  id,
  index,
  columns,
  onSelect,
  onClick,
  entitySelectors,
  dndEnabled,
  dndSettings,
  editMode,
  onEditingStart,
  onEditingEnd
}: ListRowProps) => {
  const { byId, isSelected } = entitySelectors;
  const selected = useAppSelector((state) => isSelected(state, id));
  const rowData = useAppSelector((state) => byId(state, id)) || ({} as ListEntity);
  const dndRowType = dndSettings?.getType(rowData) || DragTypes.ELEMENT;
  const dropRef = useRef<HTMLDivElement>(null);

  const handleSelect = ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    onSelect([id], checked, index);
  };

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: [DragTypes.ELEMENT, DragTypes.DIRECTORY],
    drop(item: ListColumnProps, monitor: DropTargetMonitor) {
      if (monitor.isOver() && dndRowType === DragTypes.DIRECTORY && item.id !== id) {
        dndSettings?.onDrop(item.id, id);
      }
    },
    canDrop: () => dndRowType === DragTypes.DIRECTORY && dndEnabled,
    collect(monitor) {
      return {
        isOver: monitor.isOver() && dndRowType === DragTypes.DIRECTORY,
        handlerId: monitor.getHandlerId?.toString()
      };
    }
  });

  const [, drag] = useDrag({
    type: dndRowType,
    item: () => ({ id }),
    canDrag: () => dndEnabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  if (dndEnabled) {
    drag(drop(dropRef));
  }

  return (
    <ListRow ref={dropRef} data-handler-id={handlerId} className={classNames({ selected, isOver })}>
      <Checkbox onChange={handleSelect} checked={selected} disableRipple />
      {columns.map((column) => (
        <ListViewColumn
          id={id}
          key={`col-${column.key}`}
          onClick={onClick}
          column={column}
          data={rowData}
          editMode={editMode}
          onEditingStart={onEditingStart}
          onEditingEnd={onEditingEnd}
        />
      ))}
    </ListRow>
  );
};
