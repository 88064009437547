import React from 'react';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import actualTheme from '@shared/theme';
import { Link as RouterLink } from 'react-router-dom';
import styles from './styles';

export const BgContainer = ({ children }: React.PropsWithChildren<any>) => {
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery(actualTheme.breakpoints.down('md'));

  // @ts-ignore
  return (
    <Grid container wrap="wrap" sx={{ minHeight: '100vh' }}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={styles.box}>
        <Stack justifyContent="center" alignItems="center" sx={styles.content}>
          <Stack justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
            <Box
              sx={{ height: isSmallScreen ? 52 : 86, width: isSmallScreen ? 52 : 86 }}
              component="img"
              src="/img/heirloom_main_logo_icon.svg"
            />
            <Typography
              variant="h1"
              sx={{ ...styles.title, marginTop: isSmallScreen ? '12px' : '34px' }}
            >
              {t('signin.title')}
            </Typography>
            <Typography variant="h2" sx={styles.subtitle}>
              {t('signin.subTitle')}
            </Typography>
            {children}
          </Stack>
        </Stack>
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
          <Typography
            align="center"
            sx={{
              fontWeight: 400,
              fontSize: isSmallScreen ? '14px' : '16px'
            }}
          >
            {t('createdYourAccountBefore')}
            <Link component={RouterLink} to="/" sx={{ fontWeight: 600 }}>
              {t('logInHere')}
            </Link>
          </Typography>
        </div>
      </Grid>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'contents',
            md: 'contents',
            lg: 'contents',
            xl: 'contents'
          }
        }}
      >
        <Grid item xs={0} sm={6} md={6} lg={6} xl={6} sx={styles.bg} />
      </Box>
    </Grid>
  );
};
