import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ListColumnDef } from '@shared/components/list-view';
import { UserRole } from '@helpers';
import {
  clearSelectedUsersId,
  changeUserListFilter,
  changeRequestStatus,
  resetSearchTerm,
  changeUsersPage
} from '@state/users/userSlice';
import { customerColumns } from '@utils/user-columns/customerColumns';
import { staffColumns } from '@utils/user-columns/staffColumns';
import { RequestStatus } from '@utils/types';

const SwitcherButton = styled(ToggleButton)(({ theme }) => ({
  width: 150,
  height: 40,
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

interface UsersListSwitcherProps {
  setColumns: (listColumnDefs: ListColumnDef[]) => void;
  selectedUserRole: string | undefined;
  isAdmin: boolean;
}

export const UserListTypeSwitcher = ({
  setColumns,
  selectedUserRole,
  isAdmin
}: UsersListSwitcherProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleChangeListType = (_: React.MouseEvent<HTMLElement>, listType: UserRole) => {
    if (!listType) return;

    dispatch(changeUsersPage(0));
    dispatch(changeUserListFilter(listType));
    dispatch(changeRequestStatus(RequestStatus.IDLE));
    dispatch(clearSelectedUsersId());
    dispatch(resetSearchTerm());
    setColumns(listType === UserRole.CUSTOMER ? customerColumns(t, i18n) : staffColumns(t, i18n));
  };

  useEffect(() => {
    if (isAdmin) return;

    dispatch(changeUserListFilter(UserRole.CUSTOMER));
  }, []);

  return (
    <ToggleButtonGroup value={selectedUserRole} exclusive onChange={handleChangeListType}>
      {isAdmin && (
        <SwitcherButton value={UserRole.STAFF} disableTouchRipple>
          {t('admin.staffList')}
        </SwitcherButton>
      )}
      <SwitcherButton value={UserRole.CUSTOMER} disableTouchRipple>
        {t('admin.customerList')}
      </SwitcherButton>
    </ToggleButtonGroup>
  );
};
