import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Sort } from '@utils/types';
import { SortDirection } from '@helpers';

interface GridSortingSelectProps {
  sort: Sort;
  changeSort: (sort: Sort) => void;
}

export const GridSortingSelect = ({ sort, changeSort }: GridSortingSelectProps) => {
  const [sortKey, setSortKey] = useState<string>(sort.key || '');
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSortKey(event.target.value);
    if (event.target.value === 'last_updated') {
      changeSort({ key: event.target.value, direction: SortDirection.ASC });
    } else {
      changeSort({ key: event.target.value, direction: SortDirection.DESC });
    }
  };

  return (
    <FormControl>
      <InputLabel id="sort-simple-select-label">Sort By</InputLabel>
      <Select
        sx={{
          justifyContent: 'center',
          display: 'inline-flex',
          mt: 0,
          mr: 2,
          ml: 0,
          alignItems: 'center',
          backgroundColor: 'white',
          width: 145,
          height: 37
        }}
        labelId="sort-controlled-select-group"
        id="multiple-checkbox"
        value={sortKey}
        label="Sort by"
        onChange={handleChange}
      >
        <MenuItem value="name">Name</MenuItem>
        <MenuItem value="content_type">Type</MenuItem>
        <MenuItem value="last_updated">Date</MenuItem>
        <MenuItem value="description">Description</MenuItem>
      </Select>
    </FormControl>
  );
};
