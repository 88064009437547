import React from 'react';
import { Alert, Stack } from '@mui/material';
import { SimpleUploadError } from '../types';

interface SimpleUploadErrorComponentProps {
  errors: SimpleUploadError[];
}

export const SimpleUploadErrorComponent: React.FC<SimpleUploadErrorComponentProps> = ({
  errors
}) => {
  return (
    <Stack flexGrow={1}>
      {!!errors.length &&
        errors.map(({ reason }) => (
          <Alert severity="error" sx={{ mb: 2 }}>
            {reason}
          </Alert>
        ))}
    </Stack>
  );
};
