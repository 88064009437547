const getAvatarColor = (name: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const getAvatarInitials = (name: string) => {
  const firstInitial = name?.trim()?.split(/\s+/)[0][0].toUpperCase();
  const secondInitial = name?.trim()?.split(/\s+/)[1][0].toUpperCase();
  return `${firstInitial}${secondInitial}`;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: getAvatarColor(name),
      fontSize: '15px',
      border: '3px solid #6ECDC5'
    },
    alt: name,
    children: getAvatarInitials(name)
  };
};
