import { InputLabel, Select, styled } from '@mui/material';

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  margin: '0 10px 0 0',
  fontSize: theme.typography.pxToRem(14),
  color: '#183857',
  fontWeight: 400,
  opacity: 0.5
}));

export const RowPerPageSelect = styled(Select)(({ theme }) => ({
  maxHeight: '30px',
  margin: '0 10px 0 0',
  fontSize: theme.typography.pxToRem(14),
  backgroundColor: '#e2ebfa',
  '& fieldset': {
    border: 'none'
  }
}));
