import { createAsyncThunk } from '@reduxjs/toolkit';
import { $AuthApi } from '@services';
import { User, UserProfileData } from '@state/types';
import { Nullable } from 'src/globalTypes';
import { MyNetworkResponse, NetworkUser, UserProfileDataResponse, UserResponse } from './types';

export const fetchUsers = createAsyncThunk<User[], string>(
  'users/fetch',
  async (userRole: string) => {
    const userResponse = await $AuthApi.get<UserResponse>(`/user/list/${userRole}`);
    return userResponse.data?.users || [];
  }
);

export const fetchMyNetwork = createAsyncThunk<NetworkUser[]>('users/myNetwork', async () => {
  const userResponse = await $AuthApi.get<MyNetworkResponse>(`/mynetwork`);
  return userResponse.data?.network || [];
});

export const fetchUserByUserId = createAsyncThunk<Nullable<UserProfileData>, string>(
  'users/userByUserId',
  async (userId: string) => {
    const userResponse = await $AuthApi.get<UserProfileDataResponse>(`/user/${userId}`);
    return userResponse.data?.user || null;
  }
);

export const fetchUserInfoByUserId = createAsyncThunk<UserProfileData, string>(
  'users/userInfoByUserId',
  async (userId: string) => {
    const userResponse = await $AuthApi.get<UserProfileData>(`/users/${userId}/profile`);
    return userResponse.data;
  }
);
