// @ts-nocheck
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const toastContainerStyle = {
  minWidth: '365px',
  maxWidth: '585px',
  width: 'auto'
};
export const toastContainerMobileStyle = {
  width: 'calc(100% - 32px)',
  margin: '16px'
};

const toastStyle = {
  padding: '18px 24px',
  borderRadius: '20px',
  alignItems: 'center'
};

const bodyStyle = {
  fontWeight: 500,
  lineHeight: '24px',
  padding: '0',
  borderRadius: '8px',
  alignItems: 'flex-start'
};

export const toastSuccessStyle = {
  backgroundColor: '#e7f3e2',
  color: '#252A34',
  borderLeft: '8px solid #89C16E',
  ...toastStyle
};

export const toastErrorStyle = {
  backgroundColor: '#fce1e1',
  ...toastStyle
};

export const bodyToastSuccessStyle = {
  color: '#252A34',
  ...bodyStyle
};

export const ContainerCloseBtn = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center'
}));

export const TextContainer = styled(Box)(() => ({
  display: 'flex',
  color: '#252a34',
  flexDirection: 'column'
}));

export const NotificationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '24px',
  marginRight: '24px',
  [`${theme.breakpoints.down('md')} and (max-width: ${theme.breakpoints.values.md}px)`]: {
    gap: '10px',
    marginRight: '10px'
  }
}));

export const MainText = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontWeight: '700',
  fontSize: '18px',
  alignItems: 'center',
  [`${theme.breakpoints.down('md')} and (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: '14px'
  }
}));

export const AdditionalText = styled(Box)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '14px',
  [`${theme.breakpoints.down('md')} and (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: '12px',
    height: 'auto'
  }
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  height: '40px',
  width: '40px',
  [`${theme.breakpoints.down('md')} and (max-width: ${theme.breakpoints.values.md}px)`]: {
    height: '35px',
    width: '35px'
  }
}));

export const bodyToastErrorStyle = {
  color: '#252A34',
  ...bodyStyle
};
