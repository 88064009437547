import React, { useState } from 'react';
import { Switch } from '@mui/material';

import { User } from '@state/types';
import { ListEntity } from '@shared/components/list-view/types';
import { Availability, UpdateUserStatus } from '@state/users/types';
import { useAppDispatch } from '@hooks/useStore';
import { updateUserStatusThunk } from '@state/users/userSlice';
import { fetchUsers } from '@state/users/thunks';
import { DEFAULT_AVAILABILITY } from '@helpers';

export const StaffStatusSwitcher = (data: ListEntity) => {
  const dispatch = useAppDispatch();
  const {
    availability: initialAvailability = DEFAULT_AVAILABILITY,
    user_id: userId,
    account_type: accountType
  } = data as User;
  const [availability, setAvailability] = useState(initialAvailability === 'true');

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setAvailability((prev) => !prev);

    if (userId) {
      const userData: UpdateUserStatus = {
        user_id: userId,
        availability: event.target.checked.toString() as Availability
      };

      await dispatch(updateUserStatusThunk(userData));
      dispatch(fetchUsers(accountType));
    }
  };

  return <Switch checked={availability} onChange={handleChange} color="primary" />;
};
