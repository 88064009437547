import { Avatar, Stack, styled, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const ReturnBackIcon = styled(KeyboardBackspaceIcon)({
  cursor: 'pointer'
});

export const FormHeader = styled(Typography)({
  fontFamily: 'Red Hat Display',
  fontSize: '1.5rem',
  fontWeight: 700,
  lineHeight: 2,
  textAlign: 'center'
});

export const AdminForm = styled(Stack)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: theme.spacing(5, 17, 0)
}));

export const FormAvatar = styled(Avatar)({
  width: 106,
  height: 106
});
