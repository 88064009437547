import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Alert, Badge, Button, FormHelperText, MenuItem, Stack } from '@mui/material';
import { PageLoader, SelectControl, TextControl } from '@shared/components';
import { EmailStartAdornment } from '@shared/components/inputs/Adornments';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import {
  createUserThunk,
  resetError,
  resetUserProfileData,
  selectActiveUserData,
  selectUserByUserId,
  updateUserThunk
} from '@state/users/userSlice';
import { RequestStatus } from '@utils/types';
import { fetchUserByUserId } from '@state/users/thunks';
import { useHistory } from 'react-router-dom';
import { UserRole } from '@helpers';
import { ChangeImageIcon } from '@features/admin/ChangeImageIcon';
import { returnBackToUserList } from '@features/admin/utils/returnBackToUserList';
import { AdminForm, FormAvatar, FormHeader } from '@features/admin/styled';
import { SimpleUploadFile, SimpleUploadError, SimpleUploadSuccess } from '@common';
import * as yup from 'yup';
import { Nullable } from 'src/globalTypes';
import { CreateUserModel, UpdateUserModel } from '@state/users/types';
import { useSelector } from 'react-redux';
import { getProfileImage } from '@utils/getProfileImage';

interface FormData {
  profile_image: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  mobileNumber: string;
}

interface StaffFormProps {
  headerText: string;
  btnText: string;
  isNewUserCreation?: boolean;
}

export const StaffForm = ({ headerText, btnText, isNewUserCreation = false }: StaffFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { error, status } = useSelector(selectActiveUserData);
  const { activeUserId } = useAppSelector(selectActiveUserData);
  const user = useAppSelector(selectUserByUserId);
  const isLoading = status === RequestStatus.LOADING;

  const [uploadImageErrors, setUploadImageErrors] = useState<SimpleUploadError[]>([]);
  const [imageUploadData, setImageUploadData] = useState<Nullable<SimpleUploadSuccess>>(null);

  const { handleChange, handleSubmit, touched, errors, values: formValues } = useFormik({
    initialValues: {
      profile_image: '',
      firstName: user?.given_name || '',
      lastName: user?.family_name || '',
      email: user?.email || '',
      role: user?.account_type || '',
      mobileNumber: user?.phone_number || ''
    },
    validationSchema: yup.object({
      firstName: yup.string().required(t('signup.form.firstName.error.required')),
      lastName: yup.string().required(t('signup.form.lastName.error.required')),
      email: yup.string().email(t('email.error.format')).required(t('email.error.required')),
      role: yup.string().required(t('validation.required')),
      mobileNumber: yup.string()
    }),
    enableReinitialize: true,
    onSubmit: async ({ firstName, lastName, email, mobileNumber, role }: FormData) => {
      if (!isNewUserCreation) {
        const userData: UpdateUserModel = {
          user_id: activeUserId as string,
          email,
          given_name: firstName,
          family_name: lastName
        };

        if (imageUploadData && imageUploadData.imageInfo) {
          userData.profile_image = imageUploadData.imageInfo.file as File;
        }

        if (mobileNumber) {
          userData.phone_number = mobileNumber;
        }

        await dispatch(updateUserThunk(userData));

        dispatch(fetchUserByUserId(activeUserId as string));

        history.goBack();
        returnBackToUserList(dispatch);

        return;
      }

      const userData: CreateUserModel = {
        role,
        email,
        given_name: firstName,
        family_name: lastName
      };

      if (imageUploadData && imageUploadData.imageInfo) {
        userData.profile_image = imageUploadData.imageInfo.file as File;
      }

      if (mobileNumber) {
        userData.phone_number = mobileNumber;
      }

      const response = await dispatch(createUserThunk(userData));

      if ('error' in response) return;

      history.goBack();
      returnBackToUserList(dispatch);
    }
  });

  const onFocusHandling = () => {
    if (!error) return;

    dispatch(resetError());
  };

  useEffect(() => {
    if (activeUserId) {
      dispatch(fetchUserByUserId(activeUserId as string));
    }
  }, [dispatch, activeUserId]);

  useEffect(() => {
    return () => {
      dispatch(resetUserProfileData());
    };
  }, []);

  useEffect(() => {
    if (!user?.profile_image) return;

    const profileImage = getProfileImage(user?.profile_image);

    setImageUploadData({
      imageInfo: {} as SimpleUploadSuccess['imageInfo'],
      base64URL: profileImage
    });
  }, [user]);

  return (
    <Stack sx={{ width: '40%', ml: '30%', mt: 4 }}>
      <FormHeader>{headerText}</FormHeader>
      <AdminForm>
        <Stack alignItems="center" component="form" onSubmit={handleSubmit}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <SimpleUploadFile
                uniqId="staffPhoto"
                onError={setUploadImageErrors}
                onSuccess={setImageUploadData}
              >
                <ChangeImageIcon />
              </SimpleUploadFile>
            }
            sx={{ mb: 5 }}
          >
            <FormAvatar alt="Avatar" src={imageUploadData ? imageUploadData.base64URL ?? '' : ''} />
          </Badge>
          <Stack flexGrow={1}>
            {!!uploadImageErrors.length &&
              uploadImageErrors.map(({ reason }, idx) => (
                <Alert key={`reason-${idx}`} severity="error" sx={{ mb: 2 }}>
                  {reason}
                </Alert>
              ))}
          </Stack>
          {Boolean(error?.length) && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Stack direction="row" sx={{ mb: 2, width: 1 }} gap={1}>
            <Stack direction="column" flexGrow={1}>
              <TextControl
                id="firstName"
                name="firstName"
                onFocus={onFocusHandling}
                value={formValues.firstName}
                error={touched.firstName && !!errors.firstName}
                label={t('user.firstName')}
                onChange={handleChange}
                placeholder={t('user.firstName')}
              >
                <FormHelperText sx={{ margin: 0 }} error>
                  {errors.firstName}
                </FormHelperText>
              </TextControl>
            </Stack>
            <Stack direction="column" flexGrow={1}>
              <TextControl
                id="lastName"
                name="lastName"
                value={formValues.lastName}
                onFocus={onFocusHandling}
                error={touched.lastName && !!errors.lastName}
                label={t('user.lastName')}
                onChange={handleChange}
                placeholder={t('user.lastName')}
              >
                <FormHelperText sx={{ margin: 0 }} error>
                  {errors.lastName}
                </FormHelperText>
              </TextControl>
            </Stack>
          </Stack>
          <Stack flexGrow={1} width="100%" sx={{ mb: 2 }}>
            <TextControl
              fullWidth
              id="email"
              name="email"
              value={formValues.email}
              onFocus={onFocusHandling}
              error={touched.email && !!errors.email}
              label={t('email.label')}
              onChange={handleChange}
              placeholder={t('email.label')}
              startAdornment={<EmailStartAdornment />}
            >
              <FormHelperText sx={{ margin: 0 }} error>
                {errors.email}
              </FormHelperText>
            </TextControl>
          </Stack>
          <Stack sx={{ mt: 3, mb: 4, height: 38, width: 1 }} flexDirection="column">
            <SelectControl
              id="role"
              name="role"
              label={t('usersView.list.columns.role')}
              value={formValues.role}
              error={touched.role && !!errors.role}
              onFocus={onFocusHandling}
              placeholder={t('admin.selectRole')}
              onChange={handleChange}
              disabled={!isNewUserCreation}
            >
              <MenuItem value={UserRole.STAFF}>Staff</MenuItem>
              <MenuItem value={UserRole.ADMIN} disabled>
                Admin
              </MenuItem>
            </SelectControl>
            {touched.role && !!errors.role && (
              <FormHelperText sx={{ margin: 0 }} error>
                {errors.role}
              </FormHelperText>
            )}
          </Stack>
          <TextControl
            id="mobileNumber"
            name="mobileNumber"
            label={t('admin.mobileNumber')}
            onFocus={onFocusHandling}
            value={formValues.mobileNumber}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 7 }}>
            {btnText}
          </Button>
        </Stack>
      </AdminForm>
      <PageLoader open={isLoading} />
    </Stack>
  );
};
