import React from 'react';
import { PageSizeOptions } from '@helpers';
import { MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnyType } from 'src/globalTypes';
import { RowPerPageSelect, StyledLabel } from './styles';

interface PageSizeSelectProps {
  pageSize: number;
  onChange: (event: AnyType) => void;
}

export const PageSizeSelect = ({ pageSize, onChange }: PageSizeSelectProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center">
      <StyledLabel variant="standard" htmlFor="uncontrolled-native">
        {t('rows-per-page')}
      </StyledLabel>
      <RowPerPageSelect id="pageSize" value={pageSize} onChange={(e) => onChange(e)}>
        <MenuItem value={PageSizeOptions.TEN}>{PageSizeOptions.TEN}</MenuItem>
        <MenuItem value={PageSizeOptions.TWENTY}>{PageSizeOptions.TWENTY}</MenuItem>
        <MenuItem value={PageSizeOptions.THIRTY}>{PageSizeOptions.THIRTY}</MenuItem>
      </RowPerPageSelect>
    </Stack>
  );
};
