import React from 'react';
import { ToastContainer, ToastContainerProps, toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';

import CloseIcon from '@shared/static-assets/svg/close.svg';
import { AUTO_CLOSE_GLOBAL_TOAST_DURATION } from '@helpers';
import {
  MainText,
  TextContainer,
  AdditionalText,
  ImageContainer,
  toastErrorStyle,
  NotificationBox,
  ContainerCloseBtn,
  toastSuccessStyle,
  bodyToastErrorStyle,
  bodyToastSuccessStyle,
  toastContainerStyle,
  toastContainerMobileStyle
} from './styles';

import 'react-toastify/dist/ReactToastify.min.css';

export const setGlobalSuccessToastMessage = (
  message: string,
  additionalMessage?: string,
  autoClose = AUTO_CLOSE_GLOBAL_TOAST_DURATION
) =>
  toast.success(
    <NotificationBox>
      <ImageContainer
        component="img"
        // @ts-ignore
        src="/img/successful_check_mark.svg"
        alt="SuccessIcon"
      />
      <TextContainer>
        <MainText>{message}</MainText>
        <AdditionalText>{additionalMessage}</AdditionalText>
      </TextContainer>
    </NotificationBox>,
    {
      bodyStyle: bodyToastSuccessStyle,
      style: toastSuccessStyle,
      autoClose
    }
  );

export const setGlobalErrorToastMessage = (message: string, additionalMessage?: string) =>
  toast.error(
    <NotificationBox>
      <ImageContainer
        component="img"
        // @ts-ignore
        src="/img/error_notification_mark.svg"
        alt="ErrorIcon"
      />
      <TextContainer>
        <MainText>{message}</MainText>
        <AdditionalText>{additionalMessage}</AdditionalText>
      </TextContainer>
    </NotificationBox>,
    {
      bodyStyle: bodyToastErrorStyle,
      style: toastErrorStyle
    }
  );

const defaultGlobalToast: ToastContainerProps = {
  position: 'top-right',
  autoClose: AUTO_CLOSE_GLOBAL_TOAST_DURATION,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'light'
};

const GlobalToast = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <ToastContainer
      {...defaultGlobalToast}
      style={isSmallScreen ? toastContainerMobileStyle : toastContainerStyle}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={() => (
        <ContainerCloseBtn>
          <img src={CloseIcon} alt="close" />
        </ContainerCloseBtn>
      )}
      icon={false}
    />
  );
};
export default GlobalToast;
