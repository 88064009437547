import { Alert, styled } from '@mui/material';
import { red } from '@mui/material/colors';
import DialogContent from '@mui/material/DialogContent';

export const ErrorAlert = styled(Alert)(() => ({
  background: red[100]
}));

export const DialogContentCustom = styled(DialogContent)(() => ({
  background: red[100],
  padding: '10px 0'
}));

export const DialogActionsCustom = styled(DialogContent)(() => ({
  background: red[100],
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px'
}));
