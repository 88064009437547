import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '@state/types';
import { ListEntity } from '@shared/components/list-view/types';
import { selectActiveUserData, setActiveUserId } from '@state/users/userSlice';
import { UserRole } from '@helpers';

export const EditUserIcon = (data: ListEntity) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { role } = useSelector(selectActiveUserData);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { user_id } = data as User;

  const onClick = () => {
    dispatch(setActiveUserId(user_id));
    history.push(`/${role === UserRole.ADMIN ? UserRole.ADMIN : UserRole.STAFF}/details`);
  };

  return <EditIcon cursor="pointer" onClick={onClick} />;
};
