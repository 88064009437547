import React, { useState, useRef, useEffect } from 'react';
import { SxProps } from '@mui/material/styles';
import { TextFormControl } from '@shared/components/inputs/Inputs';
import { Theme } from '@emotion/react';
import moment, { Moment } from 'moment';
import { getTomorrowDateByDay } from '@utils/getTomorrowDateByDay';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateFormats } from '@helpers';
import { Nullable } from 'src/globalTypes';
import { useTranslation } from 'react-i18next';
import { DatePickerSx, FormControlLabel, TextInputDatePicker } from './styles';

interface DatePickerProps {
  label?: string;
  sx?: SxProps<Theme>;
  name?: string;
  defaultValue?: string;
  inputFormat?: string;
  handleChange?: (date: Nullable<Moment>) => void;
  minDate?: Moment;
  maxDate?: Moment;
  isEnteredKeyboard?: boolean;
  mask?: '__/__/____ __:__' | '__/__/____ __:__ _M';
  disabled?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  sx,
  name = '',
  inputFormat = DateFormats.MM_DD_YYYY_SLASH_SEPARATE,
  handleChange,
  defaultValue,
  maxDate,
  minDate,
  isEnteredKeyboard = false,
  mask,
  disabled
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<Nullable<HTMLInputElement>>(null);
  const buttonCalendarRef = useRef<Nullable<HTMLButtonElement>>(null);
  const [value, setValue] = useState<Nullable<Moment>>(getTomorrowDateByDay());

  const onKeyDown = (e: React.SyntheticEvent) => e.preventDefault();

  useEffect(() => {
    if (!defaultValue) return;

    setValue(moment(defaultValue));
  }, [defaultValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TextFormControl sx={sx}>
        {label && <FormControlLabel shrink>{t(label)}</FormControlLabel>}
        <DatePickerSx
          mask={mask}
          inputFormat={inputFormat}
          disablePast
          minDate={minDate}
          maxDate={maxDate}
          disableHighlightToday
          disabled={disabled}
          closeOnSelect
          autoFocus={false}
          OpenPickerButtonProps={{
            ref: buttonCalendarRef
          }}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            handleChange?.(newValue);
          }}
          renderInput={(params) => (
            <TextInputDatePicker
              ref={inputRef}
              name={name}
              onKeyDown={!isEnteredKeyboard ? onKeyDown : undefined}
              onClick={() => {
                if (isEnteredKeyboard) return;
                buttonCalendarRef.current?.click();
              }}
              {...params}
            />
          )}
        />
      </TextFormControl>
    </LocalizationProvider>
  );
};
