const getTimeAgo = (specifiedDateLocal: Date): string => {
  const now: Date = new Date();
  const timeDifference: number = now.getTime() - specifiedDateLocal.getTime();

  const hoursAgo: number = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutesAgo: number = Math.floor((timeDifference / (1000 * 60)) % 60);

  if (hoursAgo > 0) {
    return `${hoursAgo}h ago`;
  }
  if (minutesAgo > 0) {
    return `${minutesAgo} min ago`;
  }
  return 'Just now';
};

export const prettifyTime = (serverDate: string) => {
  const now = new Date();
  const specifiedDate = new Date(serverDate);

  // Convert specifiedDate to local time zone
  const specifiedDateLocal = new Date(specifiedDate.getTime());

  // Check if specifiedDate is today
  if (now.toDateString() === specifiedDateLocal.toDateString()) {
    return getTimeAgo(specifiedDateLocal);
  }

  // Check if specifiedDate is within the same week
  const daysDiff = Math.floor((now.getTime() - specifiedDateLocal.getTime()) / (1000 * 3600 * 24));
  if (daysDiff < 7 && now.getDay() >= specifiedDateLocal.getDay()) {
    return specifiedDateLocal.toLocaleString('en', { weekday: 'short' });
  }

  // Otherwise return formatted date
  return `${(specifiedDateLocal.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${specifiedDateLocal.getDate().toString().padStart(2, '0')}`;
};

export const compareDates = (a: string, b: string) => {
  return new Date(b).getTime() - new Date(a).getTime();
};
