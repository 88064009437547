import { AuthUser } from '@contexts';
import { PAGES, UserRole } from '@helpers';

type UserRoleURL = typeof PAGES.Admin | typeof PAGES.Staff | typeof PAGES.Home;

export const getCurrentUserRole = (user: AuthUser): { role: UserRole; userUrl: UserRoleURL } => {
  const role =
    user?.getSignInUserSession()?.getIdToken().payload['custom:account_type'] ?? UserRole.CUSTOMER;
  let userUrl: UserRoleURL = PAGES.Home;

  switch (role) {
    case UserRole.ADMIN:
      userUrl = PAGES.Admin;
      break;
    case UserRole.STAFF:
      userUrl = PAGES.Staff;
      break;
    default:
      userUrl = PAGES.Home;
  }

  return { role, userUrl };
};
