import { ENVIRONMENT, PLATFORM } from '@aws';
import { getJwtTokenFromCurrentSession } from '@utils/getJwtTokeFromCurrentSession';
import axios, { AxiosRequestConfig } from 'axios';

const $AuthApi = axios.create({
  baseURL: PLATFORM[ENVIRONMENT].file_base_url,
  headers: { Pragma: 'no-cache' }
});

const $BaseApi = axios.create({
  baseURL: PLATFORM[ENVIRONMENT].file_base_url,
  headers: { Pragma: 'no-cache' }
});

$AuthApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = await getJwtTokenFromCurrentSession();

  if (config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export { $AuthApi, $BaseApi };
