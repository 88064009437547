import { AnyType } from 'src/globalTypes';
import { isNumber } from './isNumber';
import { isObject } from './isObject';

export const getFormByFormData = <T extends { [key: string]: AnyType }>(data: T): FormData => {
  const form = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (!value) return;
    if (Array.isArray(value)) return;
    if (isObject(value) && !(value instanceof File)) return;

    if (isNumber(value)) {
      // eslint-disable-next-line no-param-reassign
      value = value.toString();
    }

    form.append(key, value);
  });

  return form;
};
