import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '@contexts';
import { getCurrentUserRole } from '@utils/getCurrentUserRole';
import { useDispatch } from 'react-redux';
import { changeRole } from '@state/users/userSlice';

const PublicRoute = ({ children, ...rest }: any) => {
  const { user } = useContext(AuthContext);
  const { userUrl, role } = getCurrentUserRole(user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeRole(role));
  }, [role]); // TODO rewrite

  return (
    <Route {...rest}>
      {user && user.challengeName !== 'NEW_PASSWORD_REQUIRED' ? (
        <Redirect to={userUrl} />
      ) : (
        children
      )}
    </Route>
  );
};

export default PublicRoute;
