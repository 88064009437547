import React, { lazy } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import actualTheme from '@shared/theme';

const UserRouterDesktop = lazy(() =>
  import(/* webpackChunkName: "UserRouterDesktop" */ './user_router/userRouterDesktop')
);

const UserRouterMobile = lazy(() =>
  import(/* webpackChunkName: "UserRouterMobile" */ './user_router/userRouterMobile')
);

const userRouter = () => {
  const isMobile = useMediaQuery(actualTheme.breakpoints.down('sm'));

  return isMobile ? <UserRouterMobile /> : <UserRouterDesktop />;
};

export default userRouter;
