import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Label } from '@shared/components/inputs/Inputs';

export const TextInputDatePicker = styled(TextField)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.typography.pxToRem(14),
  fontWeight: 400,
  '& .MuiInputBase-input': {
    padding: 0,
    marginLeft: '12px',
    letterSpacing: theme.typography.pxToRem(-0.096),
    '&::placeholder': {
      color: theme.palette.text.primary,
      opacity: '0.5'
    }
  }
}));

export const FormControlLabel = styled(Label)(() => ({
  position: 'absolute',
  top: -25
}));

export const DatePickerSx = styled(MuiDatePicker)(() => ({
  border: '1px solid #DFE3EB',
  borderRadius: '5px',
  outline: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  '& fieldset': {
    border: 'none'
  }
})) as typeof MuiDatePicker;
