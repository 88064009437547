import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTooltip from '@mui/material/Tooltip';
import { Stack, Typography } from '@mui/material';

interface TooltipProps {
  title: string;
  children: React.ReactElement;

  fontSizeTitle?: number;
  textAlign?: 'left' | 'center' | 'right';
  isShowing?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  isShowing = true,
  textAlign = 'center',
  fontSizeTitle = 12
}) => {
  const { t } = useTranslation();
  return isShowing ? (
    <MaterialTooltip
      title={
        <Typography fontSize={fontSizeTitle} variant="body1" textAlign={textAlign}>
          {t(`${title}`) ?? ''}
        </Typography>
      }
      className=""
    >
      <Stack display="flex" justifyContent="center" alignItems="center">
        {children}
      </Stack>
    </MaterialTooltip>
  ) : (
    children
  );
};
