import React from 'react';
import { User } from '@state/types';
import { ListEntity } from '@shared/components/list-view/types';
import { Subscriptions } from '@helpers';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getDateFromSQLI18nLanguage } from '@utils/getDateFromSQLI18nLanguage';

export const CustomerListExpirationDateSelect = (data: ListEntity) => {
  const { t, i18n } = useTranslation();

  const { subscription, expiration } = data as User;

  const noSubscription = subscription === Subscriptions.GB_1;

  return (
    <Typography variant="body1" fontWeight={400} paddingLeft="10px">
      {noSubscription
        ? t('no-expiration')
        : getDateFromSQLI18nLanguage({ date: expiration, format: 'DD', i18n })}
    </Typography>
  );
};
