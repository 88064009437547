import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  PayloadAction
} from '@reduxjs/toolkit';
import update from 'immutability-helper';

import {
  compareByDirectoryFn,
  fetchFilesInTrashFulfillmentHandler,
  filesCompareFn,
  filesInTrashErrorHandler,
  filesInTrashFulfillmentHandler,
  filesInTrashLoadingHandler,
  fileToSearchString,
  rootTrashFolder
} from '@state/files/utils';
import { File, SelectFileActionPayload } from '@state/types';
import { TrashFilesState } from '@state/trash/types';
import { RootState } from '@store';
import { fetchFileInTrash, hardDeleteFiles, restoreFiles } from '@state/trash/thunks';
import { RequestStatus, Sort } from '@utils/types';
import { defaultSortParams } from '@helpers';
import { getBorderElements } from '@utils/pagination';

const trashAdapter = createEntityAdapter<File>({
  selectId: (file) => file.file_id
});

const initialState: TrashFilesState = trashAdapter.getInitialState({
  selectedIds: [],
  path: {
    currentDirectoryId: '.',
    parts: [rootTrashFolder]
  },
  sort: defaultSortParams,
  searchTerm: '',
  pagination: {
    totalCount: 0,
    currentPage: 0,
    pageSize: 20
  },
  status: RequestStatus.IDLE,
  error: null
});

const trashSlice = createSlice({
  name: 'trash',
  initialState,
  reducers: {
    select: (
      state: TrashFilesState,
      { payload: { ids, selected } }: PayloadAction<SelectFileActionPayload>
    ) => {
      return selected
        ? update(state, { selectedIds: { $push: ids } })
        : {
            ...state,
            selectedIds: state.selectedIds.filter((selectedId) => !ids.includes(selectedId))
          };
    },
    changeSort: (state: TrashFilesState, { payload }: PayloadAction<Sort>) => {
      state.sort = payload;
    },
    changePage: (state: TrashFilesState, { payload }: PayloadAction<number>) => {
      state.pagination.currentPage = payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchFileInTrash.fulfilled, fetchFilesInTrashFulfillmentHandler(trashAdapter))
      .addCase(fetchFileInTrash.pending, filesInTrashLoadingHandler)
      .addCase(fetchFileInTrash.rejected, filesInTrashErrorHandler)

      .addCase(hardDeleteFiles.fulfilled, filesInTrashFulfillmentHandler(trashAdapter))
      .addCase(hardDeleteFiles.pending, filesInTrashLoadingHandler)

      .addCase(restoreFiles.fulfilled, filesInTrashFulfillmentHandler(trashAdapter))
      .addCase(restoreFiles.pending, filesInTrashLoadingHandler)
});

// selectors
export const {
  selectById: selectFileById,
  selectAll: selectAllDeletedFiles
} = trashAdapter.getSelectors((state: RootState) => state.trash);

export const selectedFileIds = (state: RootState) => state.trash.selectedIds;
export const selectFilesSort = (state: RootState) => state.trash.sort;
export const selectFilesPagination = (state: RootState) => state.trash.pagination;
export const isFileSelected = (state: RootState, id: EntityId) => {
  return state.trash.selectedIds.includes(id);
};

export const selectTrashSearchedFiles = createSelector(
  selectAllDeletedFiles,
  (state: RootState) => state.trash.searchTerm,
  (entities, searchTerm) => {
    return entities.filter((file) =>
      searchTerm.trim().length ? fileToSearchString(file).includes(searchTerm) : true
    );
  }
);
export const selectSortedFileIdsFromTrash = createSelector(
  selectTrashSearchedFiles,
  (state: RootState) => state.trash.sort,
  (entities, sort) => {
    return (sort.direction === false
      ? [...entities].sort(compareByDirectoryFn)
      : [...entities].sort(filesCompareFn(sort)).sort(compareByDirectoryFn)
    )
      .slice()
      .map((file) => file.file_id);
  }
);
export const selectTrashFilesPageIds = createSelector(
  selectSortedFileIdsFromTrash,
  (state: RootState) => state.trash.pagination,
  (ids, pagination) => {
    const { firstElement, lastElement } = getBorderElements(pagination);
    return ids.slice(firstElement, lastElement + 1);
  }
);

// reducer
export default trashSlice.reducer;

// actions
export const {
  changeSort: changeTrashFilesSort,
  changePage: changeTrashFilesPage,
  select: selectTrashFiles
} = trashSlice.actions;
