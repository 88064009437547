import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Badge, Button, FormHelperText, MenuItem, Stack } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { EmailStartAdornment } from '@shared/components/inputs/Adornments';
import { SelectControl, TextControl, PageLoader } from '@shared/components';
import { ChangeImageIcon } from '@features/admin/ChangeImageIcon';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import {
  DateFormats,
  Statuses,
  Subscriptions,
  SubscriptionSource,
  SuscriptionPlans,
  UserRole
} from '@helpers';
import {
  createUserThunk,
  resetError,
  resetUserProfileData,
  selectActiveUserData,
  selectUserByUserId,
  selectUserRequestStatus,
  sendForgotResetPasswordByStaff,
  updateUserThunk
} from '@state/users/userSlice';
import { RequestStatus } from '@utils/types';
import { fetchUserByUserId } from '@state/users/thunks';
import { useHistory } from 'react-router-dom';
import { AdminForm, FormAvatar, FormHeader } from '@features/admin/styled';
import { returnBackToUserList } from '@features/admin/utils/returnBackToUserList';
import * as yup from 'yup';
import { Nullable } from 'src/globalTypes';
import {
  SimpleUploadFile,
  SimpleUploadErrorComponent,
  SimpleUploadSuccess,
  SimpleUploadError,
  DatePicker
} from '@common';
import { CreateUserModel, UpdateUserModel } from '@state/users/types';
import { getProfileImage } from '@utils/getProfileImage';
import { getExpirationDateFormat } from '@utils/getExpirationDateFormat';
import { getTomorrowDateByDay } from '@utils/getTomorrowDateByDay';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  currentPlan: string;
  mobileNumber: string;
  expiration: string;
}

interface CustomerFormProps {
  headerText: string;
  btnText: string;
  isNewUserCreation?: boolean;
}

export const CustomerForm = ({
  headerText,
  btnText,
  isNewUserCreation = false
}: CustomerFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { error } = useSelector(selectActiveUserData);

  const { activeUserId } = useAppSelector(selectActiveUserData);
  const user = useAppSelector(selectUserByUserId);
  const isInvitedStatus = user?.state === Statuses.Invited;
  const userRequestStatus = useAppSelector(selectUserRequestStatus);
  const isLoading = userRequestStatus === RequestStatus.LOADING;
  const isStripeSubscription = user?.subscription_source === SubscriptionSource.Stripe;

  const [uploadImageErrors, setUploadImageErrors] = useState<SimpleUploadError[]>([]);
  const [imageUploadData, setImageUploadData] = useState<Nullable<SimpleUploadSuccess>>(null);

  const {
    handleChange,
    handleSubmit,
    values: formValues,
    touched,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {
      firstName: user?.given_name || '',
      lastName: user?.family_name || '',
      email: user?.email || '',
      currentPlan: user?.subscription || '',
      mobileNumber: user?.phone_number || '',
      expiration: user?.expiration || moment().add(1, 'days').format(DateFormats.YYYY_MM_DD)
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required(t('signup.form.firstName.error.required')),
      lastName: yup.string().required(t('signup.form.lastName.error.required')),
      email: yup.string().email(t('email.error.format')).required(t('email.error.required')),
      currentPlan: yup.string().required(t('validation.required')),
      mobileNumber: yup.string()
    }),

    enableReinitialize: true,

    onSubmit: async ({
      firstName,
      lastName,
      email,
      mobileNumber,
      currentPlan,
      expiration
    }: FormData) => {
      if (!isNewUserCreation) {
        const userData: UpdateUserModel = {
          user_id: activeUserId as string,
          email,
          given_name: firstName,
          family_name: lastName,
          subscription: currentPlan
        };

        if (currentPlan !== Subscriptions.GB_1) {
          userData.expiration = moment(expiration).format(DateFormats.YYYY_MM_DD);
        }

        if (mobileNumber) {
          userData.phone_number = mobileNumber;
        }

        if (imageUploadData && imageUploadData.imageInfo) {
          userData.profile_image = imageUploadData.imageInfo.file as File;
        }

        const response = await dispatch(updateUserThunk(userData));

        if ('error' in response) return;

        await dispatch(fetchUserByUserId(activeUserId as string));

        history.goBack();
        returnBackToUserList(dispatch);

        return;
      }
      const userData: CreateUserModel = {
        role: UserRole.CUSTOMER,
        email,
        given_name: firstName,
        family_name: lastName,
        subscription: currentPlan
      };

      if (currentPlan !== Subscriptions.GB_1) {
        userData.expiration = moment(expiration).format(DateFormats.YYYY_MM_DD);
      }

      if (imageUploadData && imageUploadData.imageInfo) {
        userData.profile_image = imageUploadData.imageInfo.file as File;
      }

      if (mobileNumber) {
        userData.phone_number = mobileNumber;
      }

      const response = await dispatch(createUserThunk(userData));

      if ('error' in response) return;

      history.goBack();
      returnBackToUserList(dispatch);
    }
  });

  const is1GbSubscription = formValues.currentPlan === Subscriptions.GB_1;

  const onFocusHandling = () => {
    if (!error) return;

    dispatch(resetError());
  };

  const handleResetPassword = async () => {
    const response = await dispatch(sendForgotResetPasswordByStaff(formValues.email));

    if ('error' in response) return;
    history.goBack();
    returnBackToUserList(dispatch);
  };

  useEffect(() => {
    if (activeUserId) {
      dispatch(fetchUserByUserId(activeUserId as string));
    }
  }, [dispatch, activeUserId]);

  useEffect(() => {
    return () => {
      dispatch(resetUserProfileData());
    };
  }, []);

  useEffect(() => {
    if (!user?.profile_image) return;

    const profileImage = getProfileImage(user?.profile_image);

    setImageUploadData({
      imageInfo: {} as SimpleUploadSuccess['imageInfo'],
      base64URL: profileImage
    });
  }, [user]);

  return (
    <Stack sx={{ width: '40%', ml: '30%', mt: 4 }}>
      <FormHeader>{headerText}</FormHeader>
      <AdminForm>
        <Stack alignItems="center" component="form" onSubmit={handleSubmit}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <SimpleUploadFile
                uniqId="customerPhoto"
                onError={setUploadImageErrors}
                onSuccess={setImageUploadData}
              >
                <ChangeImageIcon />
              </SimpleUploadFile>
            }
            sx={{ mb: 5 }}
          >
            <FormAvatar
              alt="Avatar"
              src={imageUploadData ? imageUploadData?.base64URL ?? '' : ''}
            />
          </Badge>
          <SimpleUploadErrorComponent errors={uploadImageErrors} />
          {Boolean(error?.length) && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Stack direction="row" width="100%" sx={{ mb: 2 }} gap={1}>
            <Stack direction="column" flexGrow={1} flexBasis="100%">
              <TextControl
                id="firstName"
                name="firstName"
                value={formValues.firstName}
                label={t('user.firstName')}
                onFocus={onFocusHandling}
                error={touched.firstName && !!errors.firstName}
                onChange={handleChange}
                placeholder={t('user.firstName')}
                sx={{ mr: 2 }}
              >
                <FormHelperText sx={{ margin: 0 }} error>
                  {errors.firstName}
                </FormHelperText>
              </TextControl>
            </Stack>
            <Stack direction="column" flexGrow={1} flexBasis="100%">
              <TextControl
                id="lastName"
                name="lastName"
                onFocus={onFocusHandling}
                value={formValues.lastName}
                error={touched.lastName && !!errors.lastName}
                label={t('user.lastName')}
                onChange={handleChange}
                placeholder={t('user.lastName')}
              >
                <FormHelperText sx={{ margin: 0 }} error>
                  {errors.lastName}
                </FormHelperText>
              </TextControl>
            </Stack>
          </Stack>
          <Stack flexGrow={1} width="100%" sx={{ mb: 2 }}>
            <TextControl
              id="email"
              name="email"
              value={formValues.email}
              onFocus={onFocusHandling}
              error={touched.email && !!errors.email}
              label={t('email.label')}
              onChange={handleChange}
              placeholder={t('email.label')}
              inputProps={{ autoCapitalize: 'none' }}
              startAdornment={<EmailStartAdornment />}
            >
              <FormHelperText sx={{ margin: 0 }} error>
                {errors.email}
              </FormHelperText>
            </TextControl>
          </Stack>
          <Stack flexDirection="row" width="100%" gap={1} sx={{ mb: 2 }}>
            <Stack sx={{ mt: 3, mb: 3, height: 38 }} flexBasis="100%" flexDirection="column">
              <SelectControl
                id="currentPlan"
                name="currentPlan"
                label={t('admin.currentPlan')}
                disabled={isStripeSubscription}
                onFocus={onFocusHandling}
                error={touched.currentPlan && !!errors.currentPlan}
                value={formValues.currentPlan}
                placeholder={t('admin.currentPlan')}
                onChange={handleChange}
              >
                {SuscriptionPlans.map(({ id, value, title }) => (
                  <MenuItem key={id} value={value}>
                    {title}
                  </MenuItem>
                ))}
              </SelectControl>
              {touched.currentPlan && !!errors.currentPlan && (
                <FormHelperText sx={{ margin: 0 }} error>
                  {errors.currentPlan}
                </FormHelperText>
              )}
            </Stack>
            <Stack sx={{ mt: 3, mb: 3, height: 33.5 }} flexBasis="100%">
              <DatePicker
                name="expiration"
                defaultValue={formValues.expiration ?? user?.expiration ?? ''}
                handleChange={(date) =>
                  date && setFieldValue('expiration', getExpirationDateFormat(date))
                }
                disabled={isStripeSubscription || is1GbSubscription}
                label="date-expires"
                minDate={moment(getTomorrowDateByDay())}
                sx={{ height: '100%' }}
              />
            </Stack>
          </Stack>
          <TextControl
            id="mobileNumber"
            name="mobileNumber"
            onFocus={onFocusHandling}
            label={t('admin.mobileNumber')}
            value={formValues.mobileNumber}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: isInvitedStatus ? 0 : 7 }}
          >
            {btnText}
          </Button>
          <Button fullWidth variant="outlined" sx={{ mt: 3, mb: 7 }} onClick={handleResetPassword}>
            {t('password.reset')}
          </Button>
        </Stack>
      </AdminForm>
      <PageLoader open={isLoading} />
    </Stack>
  );
};
