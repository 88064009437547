import React, { memo, useEffect, useState } from 'react';
import { ButtonGroup, IconButton, Stack, styled, Tooltip, Typography } from '@mui/material';
import { Edit, Cancel, CheckCircle } from '@mui/icons-material';

import { SortValue } from '@utils/types';
import { TextControl } from '@shared/components';
import { SortDirection } from '@helpers';
import { ListColumnProps, ListHeaderColumnProps } from './types';
import { SortDouble, SortDown, SortUp } from '../icons/Sort';

const ListColumn = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: 72,
  minWidth: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}));

const ListHeadColumn = styled(ListColumn)({
  justifyContent: 'space-between',
  '& .MuiTypography-root': {
    fontWeight: 'bold'
  }
});

const ListColumnContent = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const EditButton = styled(IconButton)(({ theme }) => ({
  color: '#000000',
  padding: theme.spacing(0.25)
}));

export const ListViewColumn = memo(
  ({
    column: { key, component, editable = false, onDataChange, dataFormatter, width = '100%' },
    onClick,
    id,
    data,
    editMode,
    onEditingStart,
    onEditingEnd
  }: ListColumnProps) => {
    const [editing, setEditing] = useState(false);
    const content = data[key];
    const formattedContent =
      dataFormatter && !editable ? dataFormatter(content, data) : content?.toString().trim();
    const [showEdit, setShowEdit] = useState(false);
    const [columnContent, setColumnContent] = useState(formattedContent);

    useEffect(() => {
      if (!editMode) {
        setShowEdit(false);
        setEditing(false);
      }
    }, [editMode]);

    useEffect(() => {
      setColumnContent(formattedContent);
    }, [formattedContent]);

    const handleContentChange = (event: React.SyntheticEvent<EventTarget>) => {
      const target = event.target as HTMLInputElement;
      const updatedContent = target.value;

      setColumnContent(updatedContent);
    };

    const handleSaveContentChanges = () => {
      if (!onDataChange) {
        throw new Error('Editable columns require a value change handler.');
      }

      if (columnContent) {
        onDataChange(columnContent, id);
      } else {
        setColumnContent(formattedContent);
      }

      setEditing(false);
    };

    const handleClick = () => {
      onClick?.(data);
    };

    const showEditButton = () => {
      if (editable && !editing && !showEdit) {
        setShowEdit(true);
      }
    };

    const hideEditButton = () => {
      if (editable && !editing && showEdit) {
        setShowEdit(false);
      }
    };

    return (
      <ListColumn
        onDoubleClick={handleClick}
        onMouseOver={showEditButton}
        onMouseLeave={hideEditButton}
        sx={{ width }}
      >
        {component ? (
          component(data)
        ) : (
          <Tooltip title={columnContent ?? false} placement="top">
            <>
              {editMode && editing ? (
                <TextControl
                  value={columnContent}
                  onChange={handleContentChange}
                  onDoubleClick={(e) => e.stopPropagation()}
                />
              ) : (
                <ListColumnContent>{columnContent}</ListColumnContent>
              )}
              {editing && (
                <ButtonGroup sx={{ pl: 1 }}>
                  <EditButton onClick={handleSaveContentChanges} sx={{ color: 'primary.main' }}>
                    <CheckCircle />
                  </EditButton>
                  <EditButton
                    onClick={() => {
                      setEditing(false);
                      onEditingEnd();
                    }}
                  >
                    <Cancel />
                  </EditButton>
                </ButtonGroup>
              )}
              {showEdit && !editing ? (
                <EditButton
                  onClick={() => {
                    onEditingEnd();
                    setTimeout(() => {
                      onEditingStart();
                      setEditing(true);
                    }, 50);
                  }}
                  sx={{ ml: 1 }}
                >
                  <Edit sx={{ fontSize: '1.375rem' }} />
                </EditButton>
              ) : null}
            </>
          </Tooltip>
        )}
      </ListColumn>
    );
  },
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data && prevProps.editMode === nextProps.editMode
);

export const ListViewHeadColumn = ({
  column: { key, headerName, sortable, headerCustomStyles, width = '100%' },
  activeSort: { key: sortKey, direction },
  onSortChange
}: ListHeaderColumnProps) => {
  const activeSortKey = key === sortKey;

  const getNextDirection = (currentDirection = direction): SortValue => {
    switch (currentDirection) {
      case SortDirection.DESC:
        return SortDirection.ASC;
      case SortDirection.ASC:
        return false;
      default:
        return SortDirection.DESC;
    }
  };

  const handleSortChange = () => {
    if (!sortable) return;

    onSortChange({
      key,
      direction: !activeSortKey ? getNextDirection(false) : getNextDirection(direction)
    });
  };

  const getSortIcon = () => {
    if (!sortable || !activeSortKey) {
      return <SortDouble />;
    }
    switch (direction) {
      case SortDirection.ASC:
        return <SortUp />;
      case SortDirection.DESC:
        return <SortDown />;
      default:
        return <SortDouble />;
    }
  };

  return (
    <ListHeadColumn
      onClick={handleSortChange}
      sx={{ ...headerCustomStyles, width, justifyContent: 'flex-start' }}
    >
      <ListColumnContent sx={{ whiteSpace: 'break-spaces', lineHeight: 1, overflow: 'initial' }}>
        {headerName}
      </ListColumnContent>
      {sortable && getSortIcon()}
    </ListHeadColumn>
  );
};
