import React, { useState } from 'react';
import { MenuItem, Stack } from '@mui/material';

import { User } from '@state/types';
import { ListEntity } from '@shared/components/list-view/types';
import { SelectControl } from '@shared/components';
import { Subscriptions, SubscriptionSource, SuscriptionPlans, UserRole } from '@helpers';
import Progress from '@shared/components/Progress';
import { useAppDispatch } from '@hooks/useStore';
import { updateUserSubscriptionThunk } from '@state/users/userSlice';
import { UpdateUserModel } from '@state/users/types';
import { fetchUsers } from '@state/users/thunks';
import { Nullable } from 'src/globalTypes';

export const CustomerListPlanSelect = (data: ListEntity) => {
  const [isLoading, setLoading] = useState(false);

  const { subscription, user_id: userId } = data as User;
  const dispatch = useAppDispatch();
  const [currentPlan, setCurrentPlan] = useState<Nullable<Subscriptions>>(null);
  const isStripeSubscription = data?.subscription_source === SubscriptionSource.Stripe;

  const handleChange = async (event: { target: { value: React.SetStateAction<string> } }) => {
    setLoading(true);
    setCurrentPlan(event.target.value as Subscriptions);

    if (userId) {
      const userData: UpdateUserModel | { subscription: Subscriptions } = {
        user_id: userId,
        subscription: event.target.value as Subscriptions
      };

      await dispatch(updateUserSubscriptionThunk(userData));
      await dispatch(fetchUsers(UserRole.CUSTOMER));
    }

    setLoading(false);
  };

  return (
    <Stack width="100%" height="auto" position="relative">
      <SelectControl
        disabled={isStripeSubscription}
        value={currentPlan ?? subscription}
        onChange={handleChange}
        sx={{ height: '40px' }}
      >
        {SuscriptionPlans.map(({ id, value, title }) => (
          <MenuItem key={id} value={value}>
            {title}
          </MenuItem>
        ))}
      </SelectControl>
      {isLoading && <Progress size={25} position="absolute" />}
    </Stack>
  );
};
