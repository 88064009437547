import React, { PropsWithChildren } from 'react';

import useAuth from '@hooks/useAuth';
import { AuthContext } from '@contexts';

export const AuthProvider = ({ children }: PropsWithChildren<any>) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
