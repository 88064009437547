// @ts-nocheck
import { DocumentContentType, SortDirection } from '@helpers';
import { EntityAdapter, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { TrashFilesState } from '@state/trash/types';
import { RequestStatus, Sort } from '@utils/types';
import { DirectoryInfo, FetchFilesPayload, File } from '../types';

export const rootTrashFolder: DirectoryInfo = { id: '.', name: 'TRASH' };

export const filesCompareFn = ({ key, direction }: Sort) => (a: File, b: File) => {
  const aValue = a[key]?.toString() || '';
  const bValue = b[key]?.toString() || '';
  const localeCompare = aValue.localeCompare(bValue);
  return direction === SortDirection.DESC ? localeCompare : localeCompare * -1;
};

export const fileToSearchString = ({ name, description, content_type }: File) => {
  return `${name}${description}${content_type}`;
};

export const updateTotalFilesCount = (state: TrashFilesState) => {
  const { searchTerm } = state;
  state.pagination.totalCount = Object.values(state.entities).filter((file) =>
    searchTerm.trim().length ? fileToSearchString(file as File).includes(searchTerm) : true
  ).length;
};

export const filesInTrashLoadingHandler = (state: TrashFilesState) => {
  state.status = RequestStatus.LOADING;
};

export const filesInTrashErrorHandler = (state: TrashFilesState, action: any) => {
  state.status = RequestStatus.FAILED;
  state.error = action.error.message;
};

export const fetchFilesInTrashFulfillmentHandler = (filesAdapter: EntityAdapter<File>) => (
  state: TrashFilesState,
  { payload }: PayloadAction<FetchFilesPayload>
) => {
  state.status = RequestStatus.IDLE;
  state.pagination.totalCount = payload.files.length;
  state.pagination.currentPage = 0;
  state.files = payload.files;
  filesAdapter.setAll(state, payload.files);
};

export const filesInTrashFulfillmentHandler = (trashAdapter: EntityAdapter<File>) => (
  state: TrashFilesState,
  { payload }: PayloadAction<EntityId[]>
) => {
  state.status = RequestStatus.IDLE;
  state.selectedIds = state.selectedIds.filter((id) => !payload.includes(id));
  trashAdapter.removeMany(state, payload);
  updateTotalFilesCount(state);
};

export const compareByDirectoryFn = (a: File, b: File) => {
  if (a.content_type === DocumentContentType.DIRECTORY) {
    if (b.content_type === DocumentContentType.DIRECTORY) {
      return a.name.localeCompare(b.name);
    }

    return -1;
  }
  if (b.content_type === DocumentContentType.DIRECTORY) {
    return 1;
  }

  return 0;
};
