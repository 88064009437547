export interface NotAllowedUploadError {
  data: {
    error: unknown;
  };
}

export const checkErrorBodyByDownload = (error: unknown): error is NotAllowedUploadError =>
  typeof error === 'object' &&
  !!error &&
  'data' in error &&
  'error' in (error as NotAllowedUploadError).data;

export const checkIsStringErrorMessageBodyByDownload = (
  errorMessage: unknown
): errorMessage is string => typeof errorMessage === 'string';
