import { UserProfileData } from '@state/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useGetCustomerAccountInfoQuery } from '@state/apiSlice';
import { Statuses, SubscriptionSource } from '@helpers';

const useGetCustomerInfo = (): {
  user: UserProfileData | undefined;
  isLoading: boolean;
  isSuspendedStatus: boolean;
  isStripeSubscription: boolean;
  isError: boolean;
  refetch: () => void;
  error: FetchBaseQueryError | SerializedError | undefined;
} => {
  const {
    data: user,
    isLoading,
    isFetching,
    error,
    isError,
    refetch
  } = useGetCustomerAccountInfoQuery(undefined, {
    refetchOnReconnect: true
  });
  const isSuspendedStatus = user?.profile?.state === Statuses.Suspend;
  const isStripeSubscription =
    user?.profile?.subscription_source === SubscriptionSource.Stripe ||
    user?.profile?.subscription_source === SubscriptionSource.Staff;

  return {
    user: user?.profile,
    isLoading: isLoading || isFetching,
    refetch,
    isSuspendedStatus,
    isStripeSubscription,
    error,
    isError
  };
};

export default useGetCustomerInfo;
