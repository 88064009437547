import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FileService } from '@services';
import { isString } from '@utils/isString';
import { Nullable } from '../../globalTypes';
import { inputStyles } from './styles';
import { SimpleUploadError, SimpleUploadSuccess } from './types';

interface UploadFileProps {
  children: React.ReactNode;
  uniqId: string;
  onError?: (errors: SimpleUploadError[]) => void;
  onSuccess?: (data: Nullable<SimpleUploadSuccess>) => void;
}

export const SimpleUploadFile: React.FC<UploadFileProps> = ({
  children,
  onSuccess,
  onError,
  uniqId
}) => {
  const inputUploadFileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    onError?.([]);
    onSuccess?.(null);

    const {
      target: { files }
    } = e;

    if (!files || !files.length) return;

    if (files && inputUploadFileRef.current) {
      const file = files[0];
      const errors: any[] = [];

      if (FileService.isInvalidSize(file)) {
        errors.push({ reason: t('imageRule.size') });
      }

      if (FileService.isInvalidFormat(file)) {
        errors.push({ reason: t('imageRule.format') });
      }

      if (errors.length) {
        onError?.(errors);
        return;
      }
      const format = file.type.split('/')[1];

      const imageInfo = {
        name: file.name,
        size: file.size,
        type: format,
        file
      };

      const base64URL = await FileService.readBlob(file);

      inputUploadFileRef.current.value = '';

      onSuccess?.({ base64URL: isString(base64URL) ? base64URL : '', imageInfo });
    }
  };

  return (
    <label htmlFor={uniqId}>
      {children}
      <input
        id={uniqId}
        onChange={handleUploadFile}
        ref={inputUploadFileRef}
        type="file"
        style={inputStyles}
      />
    </label>
  );
};
