import React from 'react';
import { Stack } from '@mui/material';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SignUp from './signUp';
import SignUpConfirm from './signUpConfirm';
import SignUpComplete from './signUpComplete';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh', overflowY: 'auto' }}
      flexGrow={0}
      flexShrink={1}
      flexBasis="700px"
    >
      <Switch>
        <Route exact path={`${path}/confirm`} component={SignUpConfirm} />
        <Route exact path={`${path}/complete`} component={SignUpComplete} />
        <Route exact path={path} component={SignUp} />
      </Switch>
    </Stack>
  );
};
